import moment from "moment";

export const setAccessToken = (value) => {
  localStorage.setItem("access_token", value);
};

export const getAccessToken = () => {
  if (localStorage.getItem("access_token")) return localStorage.getItem("access_token");
  return null;
};

export const setRefreshToken = (value) => {
  localStorage.setItem("refresh_token", value);
};

export const getRefreshToken = () => {
  if (localStorage.getItem("refresh_token")) return localStorage.getItem("refresh_token");
  return null;
};

export const setUserInfo = (value) => {
  localStorage.setItem("userInfo", value);
};

export const getUserInfo = () => {
  if (localStorage.getItem("userInfo")) return localStorage.getItem("userInfo");
  return null;
};

export const setTokenCreationTimeStamp = () => {
  const date = moment(new Date());
  localStorage.setItem("token_creation_date", date);
};

export const getTokenCreationTimeStamp = () => {
  if (localStorage.getItem("token_creation_date")) return localStorage.getItem("token_creation_date");
  return null;
};

export const clearLocalStorge = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token_creation_date");
  localStorage.removeItem("userInfo");
};
