import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import { getAPI, patchAPIWrapper } from "../../../../utils/api";
import { GET_ALL_CLIENTS, GET_PROJECT_INFO } from "../../../../utils/apiUrls";
import { Loader } from "../../../CommonComponents";
import "./editProject.css";

const EditProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");
  const [clients, setClients] = useState([]);
  const [file, setFile] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    document_links: "",
    description: "",
    start_date_picker: null,
    end_date_picker: null,
    is_complete: false,
    clients_dd: [],
    client_ids: [],
    logo: null,
  });

  const getProjectInfo = async () => {
    try {
      const response = await getAPI(GET_PROJECT_INFO + id);
      if (response.isError) throw response.error;
      setFormData({
        name: response.data?.name || "",
        description: response.data?.description || "",
        document_links: response.data?.document_links || "",
        start_date_picker: response.data?.start_date
          ? new Date(response.data?.start_date)
          : null,
        end_date_picker: response.data?.end_date
          ? new Date(response.data?.end_date)
          : null,
        is_complete: response.data?.is_complete || false,
        clients_dd: response.data?.clients || [],
        client_ids: response.data?.clients
          ? response.data.clients.map((client) => client.id)
          : [],
        logo: response.data?.logo || null,
      });
    } catch (error) {
      NotificationManager.error(error, "Error");
    } finally {
      setSpinnerDisplay("none");
    }
  };

  useEffect(() => {
    if (id) getProjectInfo();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const _file = e.target.files[0];
    setFile(_file);
  };

  const handleClientsChange = (selectedClients) => {
    setFormData((prevData) => ({
      ...prevData,
      clients_dd: selectedClients,
      client_ids: selectedClients.map((client) => client.id),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinnerDisplay("flex");
    try {
      const formDataToPOST = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (key === "logo" && file) {
          formDataToPOST.append("logo", file);
        } else if (key !== "clients_dd" && key !== "logo") {
          formDataToPOST.append(key, value);
        }
      });
      formDataToPOST.append("client_ids", JSON.stringify(formData.client_ids));

      const response = await patchAPIWrapper(
        `${GET_PROJECT_INFO}${id}`,
        formDataToPOST,
        true
      );
      if (response?.isError) {
        throw new Error(response?.error);
      }
      navigate("/projects");
      NotificationManager.success("Request sent successfully", "Success");
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      console.log("error", error);
    } finally {
      setSpinnerDisplay("none");
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      const response = await getAPI(GET_ALL_CLIENTS);
      if (!response.isError) {
        setClients(response.data);
      } else {
        console.error("Failed to fetch clients.");
      }
      setSpinnerDisplay("none");
    };

    fetchClients();
  }, []);

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Edit Project</h2>
        <button
          type="button"
          className="btn btn-round p-2 mb-2 me-2"
          onClick={() => navigate("/clients")}
        >
          <i className="fas fa-list" /> Clients
        </button>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter Name"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              rows={4}
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter Description"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label>Document Links</Form.Label>
            <Form.Control
              as="textarea"
              name="document_links"
              rows={2}
              value={formData.document_links}
              onChange={handleInputChange}
              placeholder="Enter Document Links"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3 d-flex flex-column">
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={formData.start_date_picker}
              onChange={(date) => {
                setFormData((prevData) => ({
                  ...prevData,
                  start_date: moment(date).format("YYYY-MM-DD"),
                  start_date_picker: date,
                }));
              }}
              minDate={new Date()}
              dateFormat="yyyy-MM-dd"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3 d-flex flex-column">
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={formData.end_date_picker}
              onChange={(date) => {
                setFormData((prevData) => ({
                  ...prevData,
                  end_date: moment(date).format("YYYY-MM-DD"),
                  end_date_picker: date,
                }));
              }}
              minDate={new Date()}
              dateFormat="yyyy-MM-dd"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Check
              type="checkbox"
              id="is_complete"
              checked={formData.is_complete}
              label="Completed"
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  is_complete: e.target.checked,
                }));
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3">
            {formData.logo && typeof formData.logo === "string" && (
              <div className="mb-3">
                <img
                  src={formData.logo}
                  alt="Project Logo"
                  style={{ width: "100px", height: "auto" }}
                />
              </div>
            )}
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label>Clients</Form.Label>
            <Typeahead
              id="clients_dropdown"
              labelKey="name"
              multiple
              onChange={handleClientsChange}
              options={clients}
              placeholder="Choose Clients"
              selected={formData.clients_dd}
            />
          </Form.Group>
        </Row>
        <div className="text-center mt-2">
          {spinnerDisplay === "flex" ? (
            <Loader displayState={spinnerDisplay} />
          ) : (
            <button className="submit-button" type="submit">
              Update
            </button>
          )}
        </div>
      </Form>
    </>
  );
};

export default EditProject;
