/* eslint-disable import/prefer-default-export */
export const REQ_STATUS = {
    PENDING: "Pending",
    APPROVED: "Approved",
    REJECTED: "Rejected"
};

export const PRESENCE_STATUS = {
    ABSENT: "absent",
    PRESENT: "present",
    WFH: "wfh",
    LEAVE: "leave"
};