import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import Loader from "../../CommonComponents/Loader";
import { getUserInfo } from "../../../utils/localStorage";
import { GET_SALARY, GET_SALARY_PDF } from "../../../utils/apiUrls";
import { getAPI, getApiPdf, updateAuthToken } from "../../../utils/api";
import "./SalaryDetails.css";

function format_data(arr) {
  return arr.reduce((accumulator, currentValue) => {
    const existingType = accumulator.find(
      (item) => item.name === currentValue.type
    );

    if (existingType) {
      existingType.total += currentValue.amount;
      existingType.data.push(currentValue);
    } else {
      accumulator.push({
        name: currentValue.type,
        total: currentValue.amount,
        data: [currentValue],
      });
    }

    return accumulator;
  }, []);
}

const SalaryDetails = () => {
  const salaryFormat = new Intl.NumberFormat("en-IN");
  const [startDate, setStartDate] = useState(new Date());
  const [userInfo, setUserInfo] = useState({});
  const [userInfoRender, setUserInfoRender] = useState({});
  const [openCollapse, setOpenCollapse] = useState(true);
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");
  const [baseSalary, setBaseSalary] = useState(0);
  const [allowances, setAllowances] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [exportSalarySpinner, setExportSalarySpinner] = useState("none");
  const [showModal, setShowModal] = useState(false);
  const [modelData, setModalData] = useState({
    data: [],
  });

  const exportSalaryToPdf = async () => {
    setExportSalarySpinner("flex");
    const response = await getApiPdf(GET_SALARY_PDF, {
      month: startDate.getMonth() + 1,
      year: startDate.getFullYear(),
    });
    if (!response.isError) {
      window.open(response.data.data.salary_report, "_blank");
    }
    setExportSalarySpinner("none");
  };

  const getSalary = async () => {
    const response = await getAPI(GET_SALARY, {
      month: startDate.getMonth() + 1,
      year: startDate.getFullYear(),
    });

    if (response.isError) console.log("Error!");
    else {
      if (response.data.length > 0) {
        setBaseSalary(response.data[0].monthly_salary);
        setAllowances(format_data(response.data[0].allowances));
        setDeductions(format_data(response.data[0].deductions));
      } else {
        setBaseSalary(0);
        setAllowances(0);
        setDeductions(0);
      }
      setSpinnerDisplay("none");
    }
  };

  useEffect(() => {
    getSalary();
  }, [startDate]);

  useEffect(() => {
    updateAuthToken();
    setUserInfo(JSON.parse(getUserInfo()));
  }, []);

  useEffect(() => {
    const {
      first_name,
      last_name,
      email,
      designation,
      status,
      date_joined,
      cnic,
    } = userInfo;
    setUserInfoRender({
      "Employee Name": `${first_name} ${last_name}`,
      Email: email,
      Designation: designation?.designation?.name,
      "Employement Status": status,
      "Date Of Joining":
        typeof date_joined !== "undefined"
          ? date_joined.substring(0, 10)
          : date_joined,
      CNIC: cnic,
    });
  }, [userInfo]);

  const handleCloseModal = () => {
    setShowModal(!showModal);
  };

  const showDetail = (data) => {
    setShowModal(true);
    setModalData(data);
  };

  return (
    <>
      <Loader displayState={exportSalarySpinner} message="Exporting!" />
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-1">
        <div className=" d-flex date-container">
          <DatePicker
            dateFormat="MM-yyyy"
            showMonthYearPicker
            selected={startDate}
            maxDate={new Date()}
            onChange={(date) => {
              setStartDate(date);
              setSpinnerDisplay("flex");
            }}
          />
        </div>
        <h3 className="disp-salary">{`Salary Slip for ${startDate.toLocaleDateString(
          "en-US",
          { year: "numeric", month: "long" }
        )}`}</h3>
        <button
          type="button"
          className="btn btn-primary"
          onClick={exportSalaryToPdf}
        >
          Export Pdf
        </button>
      </div>
      <button
        type="button"
        className="toogle-btn-user  w-100 d-flex justify-content-center mt-1 py-1"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? (
          <i className="fa fa-angle-double-down fa-lg" />
        ) : (
          <i className="fa fa-angle-double-right fa-lg" />
        )}
      </button>
      <div className="salary-container mb-0 p-1 border border-top-0 ">
        <Collapse in={openCollapse}>
          <div>
            <div className="user-info border-bottom d-flex flex-column gap-2 pb-1 ">
              <div className="user-info w-100">
                {Object.keys(userInfoRender).map((key) => (
                  <div className="element" key={key}>
                    <div className="cell">{key}</div>
                    <div className="cell bg-white">{userInfoRender[key]}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Collapse>
        <table className="table-salary mt-1 border w-100">
          <thead>
            <tr key="table_head" className="table-salary-tr bg-dark text-white">
              <th className="table-salary-th">Title</th>
              <th className="table-salary-th">Amount</th>
            </tr>
          </thead>
          <tbody>
            {baseSalary > 0 ? (
              <tr className="table-salary-hover">
                <td>Base Salary</td>
                <td>{salaryFormat.format(baseSalary)}</td>
              </tr>
            ) : (
              <tr>
                <td colSpan="2">No Data!</td>
              </tr>
            )}
            {allowances.length > 0
              ? allowances
                  .filter((data) => data.total > 0)
                  .map((data) => (
                    <tr
                      key={data.id}
                      className="table-salary-hover"
                      onClick={() => showDetail(data)}
                    >
                      <td>
                        {data.name === "Other"
                          ? `${data.name} Allowances`
                          : data.name}
                      </td>
                      <td>{salaryFormat.format(data.total)}</td>
                    </tr>
                  ))
              : null}
            {deductions.length > 0
              ? deductions
                  .filter((data) => data.total > 0)
                  .map((data) => (
                    <tr
                      key={data.id}
                      className="table-salary-hover"
                      onClick={() => showDetail(data)}
                    >
                      <td>
                        {data.name === "Other"
                          ? `${data.name} Deductions`
                          : data.name}
                      </td>
                      <td className="text-danger">
                        {`-${salaryFormat.format(data.total)}`}
                      </td>
                    </tr>
                  ))
              : null}
          </tbody>
          <tfoot>
            <tr className="table-salary-tr bg-dark text-white">
              <td className="table-salary-th">Net Salary</td>
              <td className="table-salary-th">
                {allowances.length > 0 && deductions.length > 0
                  ? salaryFormat.format(
                      baseSalary +
                        allowances.reduce(
                          (prev, next) => prev + next.total,
                          0
                        ) -
                        deductions.reduce((prev, next) => prev + next.total, 0)
                    )
                  : allowances.length > 0 && deductions.length === 0
                    ? salaryFormat.format(
                        baseSalary +
                          allowances.reduce(
                            (prev, next) => prev + next.total,
                            0
                          )
                      )
                    : allowances.length === 0 && deductions.length > 0
                      ? salaryFormat.format(
                          baseSalary -
                            deductions.reduce(
                              (prev, next) => prev + next.total,
                              0
                            )
                        )
                      : salaryFormat.format(baseSalary)}
                <span> PKR</span>
              </td>
            </tr>
          </tfoot>
        </table>
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center font-weight-bold">
              {modelData.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table-salary mt-1 border w-100">
              <thead>
                <tr key="table_head" className="table-salary-tr">
                  <th className="table-salary-th">Notes</th>
                  <th className="table-salary-th">Amount</th>
                </tr>
              </thead>
              <tbody>
                {modelData.data.length > 0
                  ? modelData.data
                      .filter((data) => data.amount > 0)
                      .map((data) => (
                        <tr key={data.id} className="table-salary-hover">
                          <td>{data.notes}</td>
                          <td>{data.amount}</td>
                        </tr>
                      ))
                  : null}
                <tr className="border-top">
                  <td>Total</td>
                  <td>{modelData.total}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default SalaryDetails;
