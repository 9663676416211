import React from "react";
import "./Loader.css";
import PropTypes from "prop-types";

const Loader = (props) => {
  const { displayState, message } = props;
  return (
    <div className="loader-spinner p-2" style={{ display: displayState }}>
      <div className="spinner-border" aria-hidden="true" />
      <h3>{message}</h3>
    </div>
  );
};

Loader.propTypes = {
  displayState: PropTypes.string,
  message: PropTypes.string,
};

Loader.defaultProps = {
  message: "Loading...",
  displayState: "flex",
};

export default Loader;
