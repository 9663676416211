import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Typeahead } from "react-bootstrap-typeahead";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import SimpleSpinner from "../../CommonComponents/SimpleSpinner";
import ReasonModal from "../../CommonComponents/ReasonModal/ReasonModal";
import ConfirmModal from "../../CommonComponents/ConfirmModal";
import {
  GET_REQUESTS,
  SEND_REQUEST,
  GET_EMPLOYEE,
  EMPLOYEE_TOTAL_LEAVE,
  DEL_REQUEST,
  GET_DRI_EMPLOYEE,
} from "../../../utils/apiUrls";
import { deleteAPIWrapper, getAPI, postAPI } from "../../../utils/api";
import { getStatusDotColor, filterWeekdays } from "../../../utils/helpers";
import { REQ_STATUS } from "../../../utils/constants";
import { Loader } from "../../CommonComponents";
import "./leaveManagement.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

const LeaveManagement = () => {
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");
  const [showModal, setShowModal] = useState({
    reason: false,
    del: false,
    request: false,
  });
  const [reasonTxt, setReasonTxt] = useState("");
  const [isLoading, setIsLoading] = useState({ req: false, del: false });
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [driEmployee, setDRIEmployee] = useState([]);
  const [totalLeave, setTotalLeave] = useState({});
  const [currentId, setCurrentId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  const navigate = useNavigate();

  const leaveTypes = [
    { label: "Casual Leave", value: "Casual Leave" },
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "WFH Absence", value: "WFH" },
  ];

  const [formData, setFormData] = useState({
    type: leaveTypes[0].value,
    to_employee_id: 0,
    cc_employee_ids: [],
    from_date_picker: new Date(),
    to_date_picker: "",
    from_date: moment(new Date()).format("YYYY-MM-DD"),
    to_date: "",
    notes: "",
    more_days: false,
  });

  async function fetchData() {
    setSpinnerDisplay("flex");
    try {
      const response = await getAPI(`${GET_REQUESTS}`, {
        page: currentPage,
      });
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setSpinnerDisplay("none");
        setLeaveData(response.data.results);
        setPageData({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    }
  }

  async function fetchEmployee() {
    setSpinnerDisplay("flex");
    try {
      const response = await getAPI(`${GET_EMPLOYEE}`);
      if (response?.isError) {
        throw new Error(
          response?.error || "An Error has occurred while fetching data"
        );
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setSpinnerDisplay("none");
        setEmployee(
          response.data.map((emp) => ({
            ...emp,
            fullName: `${emp.first_name} ${emp.last_name}`,
          }))
        );
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    }
  }

  async function fetchDRIEmployee() {
    setSpinnerDisplay("flex");
    try {
      const response = await getAPI(`${GET_DRI_EMPLOYEE}`, {
        date: moment(new Date()).format("YYYY-MM-DD"),
      });
      if (response?.isError) {
        throw new Error(
          response?.error || "An Error has occurred while fetching data"
        );
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setSpinnerDisplay("none");
        setDRIEmployee(
          response.data.map((emp) => ({
            ...emp,
            fullName: `${emp.first_name} ${emp.last_name}`,
          }))
        );
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    }
  }

  async function fetchTotalLeave() {
    try {
      const response = await getAPI(`${EMPLOYEE_TOTAL_LEAVE}`);
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setSpinnerDisplay("none");
        setTotalLeave(response?.data);
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    }
  }

  useEffect(() => {
    fetchData();
    fetchEmployee();
    fetchTotalLeave();
    fetchDRIEmployee();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const dropdownRef = useRef(null);

  const handleOpenModal = () => {
    setShowModal((prevState) => ({ ...prevState, request: true }));
  };

  const handleCloseModal = () => {
    setShowModal((prevState) => ({ ...prevState, request: false }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "cc_employee_ids") {
      setShowDropdown(!showDropdown);
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setShowDropdown(false);
    }
  };

  const handleDriChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      to_employee_id: e[0]?.id,
    }));
  };

  const handleCcChange = (e) => {
    setSelectedOptions(e);
    setFormData((prevData) => ({
      ...prevData,
      cc_employee_ids: e.map((emp) => emp.id),
    }));
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown
    const handleClickOutside = (e) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        e.target.name !== "cc_employee_ids"
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.to_employee_id === 0) {
      NotificationManager.error("Choose DRI", "Error");
      return;
    }
    setIsLoading((prevState) => ({ ...prevState, req: true }));
    try {
      const response = await postAPI(`${SEND_REQUEST}`, formData);
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("Failed to send request, please try again");
      } else {
        fetchData();
        setFormData({
          type: leaveTypes[0].value,
          to_employee_id: 0,
          cc_employee_ids: [],
          from_date: "",
          to_date: "",
          notes: "",
          more_days: false,
        });
        setSelectedOptions([]);
        NotificationManager.success("Request sent successfully", "Success");
      }
    } catch (error) {
      setFormData({
        type: leaveTypes[0].value,
        to_employee_id: 0,
        cc_employee_ids: [],
        from_date: "",
        to_date: "",
        notes: "",
        more_days: false,
      });
      setSelectedOptions([]);
      NotificationManager.error(error.message, "Error");
    } finally {
      handleCloseModal();
      setIsLoading((prevState) => ({ ...prevState, req: false }));
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleClick = (data) => {
    setShowModal((prevState) => ({ ...prevState, reason: true }));
    setReasonTxt(data);
  };

  const handleDelete = async (id) => {
    setIsLoading((prevState) => ({ ...prevState, del: true }));
    try {
      const response = await deleteAPIWrapper(`${DEL_REQUEST}${id}`);
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("Failed to send request, please try again");
      } else {
        fetchData();
        NotificationManager.success("Request deleted");
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    } finally {
      setIsLoading((prevState) => ({ ...prevState, del: false }));
      setShowModal((prevState) => ({ ...prevState, del: false }));
    }
  };

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between align-items-center pb-1 my-2">
        <h2 className="text-dark">Leaves</h2>
        <div className="btn-groups">
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={handleOpenModal}
          >
            + Request
          </button>
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("requestLeave")}
          >
            <i className="fas fa-list" /> Requested
          </button>
          <button
            type="button"
            className="btn btn-round p-2 mb-2"
            onClick={() => navigate("employeeLeave")}
          >
            <i className="fas fa-list" /> Employee
          </button>
        </div>
      </div>
      <div className="boxMain">
        <div className="box">
          <div className="title">Casual Leave</div>
          <div className="value">{totalLeave?.casual_leave || 0}</div>
        </div>
        <div className="box">
          <div className="title">Sick Leave</div>
          <div className="value">{totalLeave?.sick_leave || 0}</div>
        </div>
        <div className="box">
          <div className="title">WFH (Month)</div>
          <div className="value">{totalLeave?.wfh_month || 0}</div>
        </div>
        <div className="box">
          <div className="title">WFH (Total)</div>
          <div className="value">{totalLeave?.wfh_total || 0}</div>
        </div>
      </div>
      <div className="my-2">
        <h2 className="text-detail">Leave Details</h2>
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th>Leave Type</th>
                  <th>From</th>
                  <th>To</th>
                  <th>No of Days</th>
                  <th>Reason</th>
                  <th>Status</th>
                  <th>DRI</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {leaveData.map((leave, index) => (
                  <tr
                    className={index % 2 === 0 ? "even-row" : "odd-row"}
                    key={leave.id}
                  >
                    <td>{leave?.type}</td>
                    <td>{leave?.from_date}</td>
                    <td>{leave?.to_date}</td>
                    <td>{leave?.duration}</td>
                    <td className="mw-400px">
                      <button
                        className="btn-round"
                        type="button"
                        onClick={() => handleClick(leave?.notes)}
                      >
                        view reason
                      </button>
                    </td>
                    <td>
                      <span
                        className={`badge rounded-pill text-bg-${getStatusDotColor(
                          leave.status
                        )}`}
                      >
                        {leave.status}
                      </span>
                    </td>
                    <td>
                      {leave.to_employee.first_name}{" "}
                      {leave.to_employee.last_name}
                    </td>
                    <td>
                      <button
                        aria-label="delete"
                        className={`${
                          leave?.status === REQ_STATUS.PENDING
                            ? "text-danger"
                            : "text-secondary"
                        } btn-delete cursor-pointer`}
                        type="button"
                        onClick={
                          leave?.status === REQ_STATUS.PENDING
                            ? () => {
                                setCurrentId(leave.id);
                                setShowModal((prevState) => ({
                                  ...prevState,
                                  del: true,
                                }));
                              }
                            : null
                        }
                      >
                        <i
                          className={`fa-solid ${
                            leave?.status === REQ_STATUS.PENDING
                              ? "fa-trash"
                              : "fa-ban"
                          }`}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button
              type="button"
              className="pagination-link"
              disabled={pageData.previous === null}
              onClick={() => handlePageClick(currentPage - 1)}
            >
              Previous
            </button>

            <p className="pagination-button active">{currentPage}</p>

            <button
              type="button"
              className="pagination-link"
              disabled={pageData.next === null}
              onClick={() => handlePageClick(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Modal show={showModal?.request} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center font-weight-bold">
            Request Leave
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center font-weight-lighter">
            Please fill in your information and send it to the company
          </p>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md={6} xs={12}>
                <Form.Label>Leave Type</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="type"
                  value={formData.type}
                  onChange={(e) => handleTypeChange(e)}
                  placeholder="Select Leave Type"
                >
                  {leaveTypes.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md={6} xs={12}>
                <Form.Label>DRI</Form.Label>
                <Typeahead
                  id="employes_dropdown"
                  labelKey="fullName"
                  name="to_employee_id"
                  className="req_to"
                  onChange={(e) => handleDriChange(e)}
                  options={driEmployee}
                  placeholder="Choose your DRI"
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={12} xs={12}>
                <Form.Label>CC</Form.Label>
                <Typeahead
                  id="cc_employees_dropdown"
                  labelKey="fullName"
                  multiple
                  name="to_employee_id"
                  onChange={(e) => handleCcChange(e)}
                  options={employee}
                  placeholder=""
                  selected={selectedOptions}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={6} xs={12}>
                <Form.Label>From</Form.Label>
                <DatePicker
                  name="date"
                  required
                  showTimeSelect={false}
                  selected={formData.from_date_picker}
                  onChange={(e) => {
                    handleInputChange({
                      target: {
                        name: "from_date",
                        value: moment(e).format("YYYY-MM-DD"),
                      },
                    });
                    handleInputChange({
                      target: { name: "from_date_picker", value: e },
                    });
                  }}
                  minDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  filterDate={filterWeekdays}
                />
              </Form.Group>
              {formData.more_days ? (
                <Form.Group as={Col} md={6} xs={12}>
                  <Form.Label>To</Form.Label>
                  <DatePicker
                    name="date"
                    showTimeSelect={false}
                    selected={formData.to_date_picker}
                    onChange={(e) => {
                      handleInputChange({
                        target: {
                          name: "to_date",
                          value: moment(e).format("YYYY-MM-DD"),
                        },
                      });
                      handleInputChange({
                        target: { name: "to_date_picker", value: e },
                      });
                    }}
                    minDate={formData.from_date_picker}
                    dateFormat="yyyy-MM-dd"
                    filterDate={filterWeekdays}
                  />
                </Form.Group>
              ) : null}
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={6} xs={12}>
                <Form.Check
                  type="checkbox"
                  id="myCheckbox"
                  checked={formData.more_days}
                  label="More than one day"
                  onClick={(e) => {
                    setFormData({ ...formData, more_days: e.target.checked });
                    if (!e.target.checked) {
                      setFormData({
                        ...formData,
                        to_date: "",
                        to_date_picker: "",
                      });
                    }
                  }}
                />
              </Form.Group>
            </Row>

            <Form.Group>
              <Form.Label>Leave Reason</Form.Label>
              <Form.Control
                as="textarea"
                name="notes"
                rows={4}
                required
                value={formData.notes}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter leave reason"
                maxLength={2000}
              />
            </Form.Group>

            <div className="text-center mt-2">
              {isLoading.req ? (
                <SimpleSpinner />
              ) : (
                <button className="submit-button" type="submit">
                  Submit
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ReasonModal
        show={showModal.reason}
        text={reasonTxt}
        handleCloseModal={() =>
          setShowModal((prevState) => ({ ...prevState, reason: false }))
        }
      />
      <ConfirmModal
        show={showModal.del}
        onClose={() =>
          setShowModal((prevState) => ({ ...prevState, del: false }))
        }
        onOkClick={() => handleDelete(currentId)}
        onCancelClick={() =>
          setShowModal((prevState) => ({ ...prevState, del: false }))
        }
        title="Cancel Request"
        statement="Are you sure you want to cancel the request?"
        okTxt="Yes"
        cancelTxt="No"
        isLoading={isLoading.del}
      />
    </>
  );
};

export default LeaveManagement;
