import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { postAPI } from "../../../../utils/api";
import { GET_ALL_CLIENTS } from "../../../../utils/apiUrls";
import { Loader } from "../../../CommonComponents";
import "./addClient.css";

const AddClient = () => {
  const navigate = useNavigate();
  const [spinnerDisplay, setSpinnerDisplay] = useState("none");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    discord_username: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinnerDisplay("flex");

    try {
      const formDataToPOST = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataToPOST.append(key, value);
      });

      const response = await postAPI(GET_ALL_CLIENTS, formDataToPOST);
      if (response?.isError) {
        throw new Error(response?.error);
      }

      navigate("/clients");
      NotificationManager.success("Request sent successfully", "Success");
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    } finally {
      setSpinnerDisplay("none");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Add Client</h2>
        <div className="btn-groups">
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("/clients")}
          >
            <i className="fas fa-list" /> Clients
          </button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter Name"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter Email"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label>Discord User Name</Form.Label>
            <Form.Control
              name="discord_username"
              required
              value={formData.discord_username}
              onChange={handleInputChange}
              placeholder="Enter Discord Username"
            />
          </Form.Group>
        </Row>

        <div className="text-center mt-2">
          {spinnerDisplay === "flex" ? (
            <Loader displayState={spinnerDisplay} />
          ) : (
            <button className="submit-button" type="submit">
              Submit
            </button>
          )}
        </div>
      </Form>
    </>
  );
};

export default AddClient;
