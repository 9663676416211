import React from "react";
import { useLocation } from "react-router-dom";
import "./MachineDetails.css";

const MachineDetails = () => {
  const location = useLocation();
  const { machine } = location.state || {};

  if (!machine) {
    return <div>No machine detail information available.</div>;
  }

  return (
    <div className="mt-2 container d-flex flex-wrap-reverse justify-content-around align-items-center">
      <div className="user-data py-2">
        <div className="d-flex justify-content-between pb-1 my-2">
          <h2 className="text-dark">Machine Details:</h2>
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Type:</span>
          <input className="input-tag" readOnly value={machine.type} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Make:</span>
          <input className="input-tag" readOnly value={machine.make} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Model:</span>
          <input className="input-tag" readOnly value={machine.model} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Ram:</span>
          <input className="input-tag" readOnly value={machine.ram} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Storage:</span>
          <input className="input-tag" readOnly value={machine.storage} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Process:</span>
          <input className="input-tag" readOnly value={machine.process} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Status:</span>
          <input className="input-tag" readOnly value={machine.status} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Condition:</span>
          <input className="input-tag" readOnly value={machine.condition} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Fault Detail:</span>
          <p className="input-tag">{machine.fault_detail}</p>
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Serial Number:</span>
          <input className="input-tag" readOnly value={machine.serial_no} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Mac Address:</span>
          <input className="input-tag" readOnly value={machine.mac_address} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Extra:</span>
          <input className="input-tag" readOnly value={machine.extra} />
        </div>
        <div className="d-flex my-3 justify-content-between">
          <span className="input-label px-2 my-1">Assigned Date:</span>
          <input className="input-tag" readOnly value={machine.assign_date} />
        </div>
      </div>
    </div>
  );
};

export default MachineDetails;
