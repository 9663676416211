import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";

import moment from "moment";
import { postAPI, getAPI } from "../../../../utils/api";
import { GET_ALL_PROJECTS, GET_ALL_CLIENTS } from "../../../../utils/apiUrls";
import { Loader } from "../../../CommonComponents";
import "./addProject.css";

const AddProject = () => {
  const navigate = useNavigate();
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");
  const [client_ids, setClients] = useState([]);

  const [formData, setFormData] = useState({
    client_ids: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinnerDisplay("flex");
    try {
      const formDataToPOST = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== "client_ids") formDataToPOST.append(key, value);
      });
      formDataToPOST.append("client_ids", JSON.stringify(formData.client_ids));
      const response = await postAPI(`${GET_ALL_PROJECTS}`, formDataToPOST);
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("Failed to send request, please try again");
      } else {
        navigate("/projects");
        NotificationManager.success("Request sent successfully", "Success");
      }
    } catch (error) {
      setFormData({
        name: "",
        document_links: "",
        description: "",
        start_date_picker: null,
        end_date_picker: null,
        is_complete: false,
        clients_dd: null,
        client_ids: [],
      });
      NotificationManager.error(error.message, "Error");
    } finally {
      setSpinnerDisplay("none");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      logo: file,
    });
  };

  const getAllClientsRequests = async () => {
    const response = await getAPI(`${GET_ALL_CLIENTS}`);

    if (response.isError) console.log("ERROR");
    else {
      setClients(response.data);
      setSpinnerDisplay("none");
    }
  };

  useEffect(() => {
    getAllClientsRequests();
  }, []);

  const handleClientsChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      client_ids: e.map((client) => client.id),
      clients_dd: e,
    }));
  };

  return (
    <>
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Add Project</h2>
        <div className="btn-groups">
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("/clients")}
          >
            <i className="fas fa-list" /> Clients
          </button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md={12} xs={12} className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="input"
              name="name"
              required
              value={formData.name}
              onChange={(e) => handleInputChange(e)}
              placeholder="Enter Name"
              maxLength={2000}
            />
          </Form.Group>
          <Form.Group as={Col} md={12} xs={12} className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              rows={4}
              value={formData.description}
              onChange={(e) => handleInputChange(e)}
              placeholder="Enter Name"
              maxLength={2000}
            />
          </Form.Group>
          <Form.Group as={Col} md={12} xs={12} className="mb-3">
            <Form.Label>Document Links</Form.Label>
            <Form.Control
              as="textarea"
              name="document_links"
              rows={2}
              value={formData.document_links}
              onChange={(e) => handleInputChange(e)}
              placeholder="Enter Name"
              maxLength={2000}
            />
          </Form.Group>
          <Form.Group as={Col} md={12} xs={12} className="mb-3 d-flex flex-column">
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              name="start_date"
              showTimeSelect={false}
              selected={formData.start_date_picker}
              onChange={(e) => {
                handleInputChange({
                  target: {
                    name: "start_date",
                    value: moment(e).format("YYYY-MM-DD"),
                  },
                });
                handleInputChange({
                  target: { name: "start_date_picker", value: e },
                });
              }}
              minDate={new Date()}
              dateFormat="yyyy-MM-dd"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} xs={12} className="mb-3 d-flex flex-column">
            <Form.Label>End Date</Form.Label>
            <DatePicker
              name="end_date"
              showTimeSelect={false}
              selected={formData.end_date_picker}
              onChange={(e) => {
                handleInputChange({
                  target: {
                    name: "end_date",
                    value: moment(e).format("YYYY-MM-DD"),
                  },
                });
                handleInputChange({
                  target: { name: "end_date_picker", value: e },
                });
              }}
              minDate={new Date()}
              dateFormat="yyyy-MM-dd"
            />
          </Form.Group>
          <Form.Group as={Col} md={12} xs={12} className="mb-3">
            <Form.Check
              type="checkbox"
              id="myCheckbox"
              checked={formData.is_complete}
              label="Completed"
              onClick={(e) => {
                setFormData({ ...formData, is_complete: e.target.checked });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md={12} xs={12} className="mb-3">
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={12} xs={12} className="mb-3">
            <Form.Label>Clients</Form.Label>
            <Typeahead
              id="cc_employees_dropdown"
              labelKey="name"
              multiple
              name="to_employee_id"
              onChange={(e) => handleClientsChange(e)}
              options={client_ids}
              placeholder=""
              selected={formData.clients_dd}
            />
          </Form.Group>
        </Row>

        <div className="text-center mt-2">
          {spinnerDisplay === "flex" ? (
            <Loader displayState={spinnerDisplay} />
          ) : (
            <button className="submit-button" type="submit">
              Submit
            </button>
          )}
        </div>
      </Form>
    </>
  );
};

export default AddProject;
