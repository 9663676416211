import React from "react";
import { Outlet } from "react-router-dom";
import "./Auth.css";

const AuthComponent = () => (
  <div className="login">
    <div className="tl">
      <Outlet />
    </div>
  </div>
);

export default AuthComponent;
