import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { Error404 } from "./components/CommonComponents";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import {
  MainComponent,
  Dashboard,
  ExtraHours,
  LunchMenu,
  SalaryDetails,
  Profile,
  // ExtraHoursRequest,
  UpdatePasword,
  LeaveManagement,
  RequestLeave,
  Attendance,
  EmployeeLeave,
  OfficeTime,
  EmployeeOfficeTime,
  Allocation,
  AddAllocation,
  Projects,
  AddProject,
  EditProject,
  Clients,
  AddClient,
  EditClient,
  Machines,
  MachineDetails,
} from "./components/MainComponents";
import {
  AuthComponent,
  Login,
  ResetPassword,
  ResetPasswordConfirm,
} from "./components/AuthComponents";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<MainComponent />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="extraHour" element={<ExtraHours />} />
              <Route path="lunchMenu" element={<LunchMenu />} />
              <Route path="leaveManagement" element={<LeaveManagement />} />
              <Route
                path="leaveManagement/requestLeave"
                element={<RequestLeave />}
              />
              <Route path="salaryDetails" element={<SalaryDetails />} />
              <Route
                path="leaveManagement/employeeLeave"
                element={<EmployeeLeave />}
              />
              <Route path="profile" element={<Profile />} />
              {/* <Route path="extraHoursRequest" element={<ExtraHoursRequest />} /> */}
              <Route path="attendance" element={<Attendance />} />
              <Route path="updatePassword" element={<UpdatePasword />} />
              <Route path="attendance/officeTime" element={<OfficeTime />} />
              <Route
                path="attendance/officeTime/employeeOfficeTime"
                element={<EmployeeOfficeTime />}
              />
              <Route path="machine/me" element={<Machines />} />
              <Route path="machine/me/:id" element={<MachineDetails />} />
              <Route path="allocation" element={<Allocation />} />
              <Route path="allocation/add" element={<AddAllocation />} />
              <Route path="projects" element={<Projects />} />
              <Route path="projects/add" element={<AddProject />} />
              <Route path="projects/edit/:id" element={<EditProject />} />
              <Route path="clients" element={<Clients />} />
              <Route path="clients/add" element={<AddClient />} />
              <Route path="clients/edit/:id" element={<EditClient />} />
            </Route>
          </Route>

          <Route element={<PublicRoutes />}>
            <Route element={<AuthComponent />}>
              <Route path="/" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="resetPassword" element={<ResetPassword />} />
              <Route
                path="reset/:id/:resetToken"
                element={<ResetPasswordConfirm />}
              />
            </Route>
          </Route>

          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <NotificationContainer />
    </div>
  );
}

export default App;
