import moment from "moment";
import { PRESENCE_STATUS, REQ_STATUS } from "./constants";
import { getTokenCreationTimeStamp } from "./localStorage";

moment.suppressDeprecationWarnings = true;

export const containsSpecialChars = (str) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};

export const isAccessTokenExpired = () => {
  const tokenCreationTimeStamp = moment(getTokenCreationTimeStamp());
  const currentDateTimeStamp = moment(new Date());
  return currentDateTimeStamp.diff(tokenCreationTimeStamp, "minutes") > 2;
};

export function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = today.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function getEventColor(status) {
  switch (status) {
    case PRESENCE_STATUS.PRESENT:
      return "#32CD32";

    case PRESENCE_STATUS.ABSENT:
      return "#F08080";

    case PRESENCE_STATUS.LEAVE:
      return "#C0C0C0";

    case PRESENCE_STATUS.WFH:
      return "#DDA0DD";

    default:
      return "#fff";
  }
}

export function parseCheckinData(dataArray) {
  return dataArray.map((event) => ({
    ...event,
    backgroundColor: getEventColor(event.status.toLowerCase()),
    extendedProps: {
      ...event,
    },
  }));
}

export const getStatusDotColor = (status) => {
  switch (status) {
    case REQ_STATUS.APPROVED:
      return "success";
    case REQ_STATUS.REJECTED:
      return "danger";
    case REQ_STATUS.PENDING:
      return "warning";
    default:
      return "transparent";
  }
};

export const filterWeekdays = (date) => {
  // Sunday is 0, Monday is 1, and so on
  const day = date.getDay();
  return day !== 0 && day !== 6; // Allow only weekdays
};

export const getStartOfWeek = (d) => moment(d).startOf("isoWeek").toDate();
export const getEndOfWeek = (d) => moment(d).endOf("isoWeek").toDate();

export const isAllocationAccess = (userData) =>
  userData?.department?.name === "Executive" ||
  userData?.designation?.designation?.grade >= 8;
