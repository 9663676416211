import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import { LEAVE_REQUEST_FROM_YOU, LEAVE_REQUEST_FINE_REMOVAL } from "../../../utils/apiUrls";
import { getAPI, patchAPIWrapper, putAPI } from "../../../utils/api";
import { getStatusDotColor } from "../../../utils/helpers";
import { Loader } from "../../CommonComponents";
import ReasonModal from "../../CommonComponents/ReasonModal";
import "./requestLeave.css";
import { REQ_STATUS } from "../../../utils/constants";

const LeaveManagement = () => {
  const [spinnerDisplay, setSpinnerDisplay] = useState("block");
  const [showModal, setShowModal] = useState(false);
  const [showFineRemovalModal, setShowFineRemovalModal] = useState(false);
  const [id, setId] = useState("");
  const [leaveData, setLeaveData] = useState([]);
  const [previousLeaveData, setPreviousLeaveData] = useState([]);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reasonTxt, setReasonTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  const handleOpenModal = (leaveId) => {
    setShowModal(true);
    setId(leaveId);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenFineRemovalModal = (leaveId) => {
    setShowFineRemovalModal(true);
    setId(leaveId);
  };

  const handleCloseFineRemovalModal = () => {
    setShowFineRemovalModal(false);
    setId("");
  };

  async function fetchData() {
    setSpinnerDisplay("flex");
    try {
      const response = await getAPI(`${LEAVE_REQUEST_FROM_YOU}`, {
        page: currentPage
      });
      if (response?.isError) {
        throw new Error("Error fetching data");
      }
       else if (!response) {
        throw new Error("An error has occurred while fetching data");
      }
      else {
        setLeaveData(response.data.results.filter(leave => leave.status === REQ_STATUS.PENDING));
        setPreviousLeaveData(response.data.results.filter(leave => leave.status !== REQ_STATUS.PENDING));
        setPageData({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
        setSpinnerDisplay("none");

      }
    }
    catch (error) {
        NotificationManager.error(error.message,"Error");
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleApproveButtonClick = async () => {
    try {
      // Make an API request to your Django endpoint
      const response = await patchAPIWrapper(`${LEAVE_REQUEST_FROM_YOU}${id}`, {
        status: "Approved",
      });

      if (response?.isError) {
        throw new Error("Error fetching data");
      }
       else if (!response) {
        throw new Error("An error has occurred while fetching data");
      }
      else {
        // Handle the response from the server, e.g., show a success message
        NotificationManager.success("Request status updated!", "Success");
        fetchData();
      }
    }
    catch (error) {
      // Handle errors, e.g., show an error message
       NotificationManager.error(error.message,"Error");
    }
    finally {
      setSpinnerDisplay("none");
      setId("");
      setShowModal(false);
    }
  };

  const handleApproveFineRemovalButtonClick = async () => {
    try {
      // Make an API request to your Django endpoint
      const response = await putAPI(`${LEAVE_REQUEST_FINE_REMOVAL}${id}`);

      if (response?.isError) {
        throw new Error("Error fetching data");
      }
       else if (!response) {
        throw new Error("An error has occurred while fetching data");
      }
      else {
        // Handle the response from the server, e.g., show a success message
        NotificationManager.success("Request status updated!", "Success");
      }
    }
    catch (error) {
      // Handle errors, e.g., show an error message
       NotificationManager.error(error.message,"Error");
    }
    finally {
      setSpinnerDisplay("none");
      setId("");
      setShowFineRemovalModal(false);
    }
  };

  const handleRejectButtonClick = async () => {
    try {
      setSpinnerDisplay("flex");

      // Make an API request to your Django endpoint
      const response = await patchAPIWrapper(`${LEAVE_REQUEST_FROM_YOU}${id}`, {
        status: "Rejected",
      });

      if (response?.isError) {
        throw new Error("Error fetching data");
      }
       else if (!response) {
        throw new Error("An error has occurred while fetching data");
      }
      else {
        // Handle the response from the server, e.g., show a success message
        fetchData();
      }
    }
    catch (error) {
      // Handle errors, e.g., show an error message
      NotificationManager.error(error.message,"Error");
    }
    finally {
      setSpinnerDisplay("none");
      setId("");
      setShowModal(false);
    }
  };


  const handleClick = (data) => {
    setShowReasonModal(true);
    setReasonTxt(data);
  };

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Request Leaves</h2>
      </div>
      <div className="my-2">
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Leave Type</th>
                  <th>From</th>
                  <th>To</th>
                  <th>No of Days</th>
                  <th>Reason</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  leaveData.length === 0 
                  ?
                  <td colSpan={7}>
                    No Data to show
                  </td>
                  :
                  leaveData.map((leave, index) => (
                  <tr className={index % 2 === 0
                    ? "even-row"
                    : "odd-row"}
                  >
                    <td>
                      {leave.from_employee.first_name}
                      {" "}
                      {leave.from_employee.last_name}
                    </td>
                    <td>{leave.type}</td>
                    <td>{leave.from_date}</td>
                    <td>{leave.to_date}</td>
                    <td>{leave.duration}</td>
                    <td><button className="btn-round" type="button" onClick={() => handleClick(leave?.notes)} >view reason</button> </td>
                    <td>
                      <button
                        type="button"
                        className="btn-pop"
                        onClick={() => handleOpenModal(leave.id)}
                      >
                        <i
                          className={`far fa-dot-circle fa-lg text-${getStatusDotColor(leave.status)}`}
                        />
                        {leave.status}
                        <i className="fa fa-caret-down fa-lg" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Previous Request Leaves</h2>
      </div>
      <div className="my-2">
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Leave Type</th>
                  <th>From</th>
                  <th>To</th>
                  <th>No of Days</th>
                  <th>Reason</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  previousLeaveData.length === 0 
                  ?
                  <td colSpan={7}>
                    No Data to show
                  </td>
                  :
                  previousLeaveData.map((leave, index) => (
                  <tr className={index % 2 === 0
                    ? "even-row"
                    : "odd-row"}
                  >
                    <td onDoubleClick={()=> handleOpenFineRemovalModal(leave.id)}>
                      {leave.from_employee.first_name}
                      {" "}
                      {leave.from_employee.last_name}
                    </td>
                    <td>{leave.type}</td>
                    <td>{leave.from_date}</td>
                    <td>{leave.to_date}</td>
                    <td>{leave.duration}</td>
                    <td><button className="btn-round" type="button" onClick={() => handleClick(leave?.notes)} >view reason</button> </td>
                    <td>
                      <button type="button" className="btn-pop">
                        <i
                          className={`far fa-dot-circle fa-lg text-${getStatusDotColor(leave.status)}`}
                        />
                        {leave.status}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button
              type="button"
              className="pagination-link"
              disabled={pageData.previous===null}
              onClick={() => handlePageClick(currentPage - 1)}
            >
              Previous
            </button>

            <p className="pagination-button active">{currentPage}</p>

            <button
              type="button"
              className="pagination-link"
              disabled={pageData.next===null}
              onClick={() => handlePageClick(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center font-weight-bold">
            Leave Approve
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center font-weight-lighter">
            Are you sure you want to approved this request?
          </p>
          <div className="approved-btns">
            <button
              className="approved-button approved"
              onClick={() => handleApproveButtonClick()}
              type="submit"
            >
              Approved
            </button>
            <button
              className="approved-button"
              onClick={() => handleRejectButtonClick()}
              type="submit"
            >
              Decline
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showFineRemovalModal} onHide={handleCloseFineRemovalModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center font-weight-bold">
            Remove Fine
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center font-weight-lighter">
            Are you sure you want to remove fine of this request?
          </p>
          <div className="approved-btns">
            <button
              className="approved-button approved"
              onClick={() => handleApproveFineRemovalButtonClick()}
              type="submit"
            >
              Approved
            </button>
            <button
              className="approved-button"
              onClick={() => handleCloseFineRemovalModal()}
              type="submit"
            >
              Decline
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ReasonModal show={showReasonModal} text={reasonTxt} handleCloseModal={ () => setShowReasonModal(false) } />
    </>
  );
};

export default LeaveManagement;
