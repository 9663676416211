import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { LOGIN } from "../../../utils/apiUrls";
import {
  setAccessToken,
  setRefreshToken,
  setTokenCreationTimeStamp,
} from "../../../utils/localStorage";
import {
  postAPI,
  setApiHeader,
} from "../../../utils/api";
import {
  logo,
  mail,
  pwd,
} from "../../../assets/images";
import "../Auth.css";

const Login = () => {
  const navigate = useNavigate();
  const [openCollapse, setOpenCollapse] = useState(false);
  const icons = ["spinner-border", "fa fa-check fa-lg", "fa fa-times fa-lg"];
  const [isPasswordShown, setIsPasswordSHown] = useState(false);
  const [snackBar, setSnackBar] = useState({
    snackBarIcon: icons[0],
    snackBarText: "Signing In...",
  });
  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const togglePassword = () => {
    setIsPasswordSHown((pass) => !pass);
  };

  const handleFormData = (e) => {
    const { value, name } = e.target;
    setUserLogin({ ...userLogin, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (openCollapse) {
      setSnackBar({ ...snackBar, snackBarIcon: icons[0], snackBarText: "Signing In..." });
    }
    else {
      setOpenCollapse(true);
    }

    const response = await postAPI(LOGIN, userLogin);
    if (response.isError) {
      setSnackBar({
        ...snackBar,
        snackBarIcon: icons[2],
        snackBarText: response.error,
      });
    }
    else {
      setSnackBar({ ...snackBar, snackBarIcon: icons[1], snackBarText: "Logged In Successfully..." });
      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      setTokenCreationTimeStamp();
      setApiHeader();
      navigate(
        response.data.last_login === null
          ? "/updatePassword"
          : "/dashboard",
      );
    }
  };

  return (
    <div>
      <div className="login">
        <div className="tl">
          <img src={logo} alt="logo" className="logo-auth p-4" />
          <div className="login-form position-absolute top-50 start-50 translate-middle p-4">
            <h2 className="mb-3 text-white">Employee Login</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 d-flex align-items-center">
                <img src={mail} alt="Email" />
                <input
                  type="email"
                  name="email"
                  value={userLogin.email}
                  onChange={handleFormData}
                  className="input-data form-control mx-4 text-white"
                  placeholder="Email ID"
                  required
                />
              </div>
              <div className="mb-3 d-flex align-items-center">
                <img src={pwd} alt="Password" />
                <input
                  type={isPasswordShown
                    ? "text"
                    : "password"}
                  name="password"
                  value={userLogin.password}
                  onChange={handleFormData}
                  className="input-data form-control mx-4 text-white"
                  placeholder="Password"
                  required
                />
              </div>
              <div className="mb-3 d-flex align-items-center justify-content-start mx-4">
                <div className="mx-5 d-flex gap-2">
                  <input
                    id="checkbox"
                    type="checkbox"
                    checked={isPasswordShown}
                    onChange={togglePassword}
                  />
                  <span className="text-white">Show Password</span>
                </div>
              </div>
              <Collapse in={openCollapse} dimension="width">
                <div id="example-collapse-text">
                  <div className="mb-3 d-flex align-items-center gap-4  login-spinner">
                    <div className={snackBar.snackBarIcon} aria-hidden="true" />
                    <h3 className="text-white mx-3">{snackBar.snackBarText}</h3>
                  </div>
                </div>
              </Collapse>
              <div className="mb-3 mx-5 d-flex align-items-center justify-content-between">
                <button type="submit" className="btn btn-primary mx-3">Login</button>
                <Link to="/resetPassword" className="mx-3 resetpass-btn">Reset Password</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
