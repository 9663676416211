import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  GET_EMPLOYEE_ALLOCATION,
  GET_ALL_DEPARTMENTS,
  ALL_PROJECTS,
  POST_EMPLOYEE_ALLOCATION,
} from "../../../../utils/apiUrls";
import { getAPI, postAPI } from "../../../../utils/api";
import { getStartOfWeek, getEndOfWeek } from "../../../../utils/helpers";
import { Loader } from "../../../CommonComponents";
import "./addallocation.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

const AddAllocation = () => {
  const ref = React.createRef();

  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");
  const [allocationData, setAllocationData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [department, setDepartment] = useState(null);
  const refDatePicker = React.createRef();
  const [date, setDate] = useState({
    start_date: moment(getStartOfWeek(new Date())).format("YYYY-MM-DD"),
    end_date: moment(getEndOfWeek(new Date())).format("YYYY-MM-DD"),
    start: getStartOfWeek(new Date()),
    end: getEndOfWeek(new Date()),
  });

  async function fetchDepartmentData() {
    try {
      const response = await getAPI(`${GET_ALL_DEPARTMENTS}`);
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setDepartments([
          {
            id: null,
            name: "All",
          },
          ...response.data,
        ]);
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    }
  }

  async function fetchProjectsData() {
    try {
      const response = await getAPI(`${ALL_PROJECTS}`);
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setProjects(response.data);
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    }
  }

  async function fetchData() {
    setSpinnerDisplay("flex");
    try {
      const response = await getAPI(`${GET_EMPLOYEE_ALLOCATION}`, {
        department,
      });
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setSpinnerDisplay("none");
        setAllocationData(response.data);
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    }
  }

  useEffect(() => {
    fetchDepartmentData();
    fetchProjectsData();
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [department]);

  const handleProjectChange = (employee, selectedProjects) => {
    setAllocationData(
      allocationData.map((emp) => {
        if (emp.id === employee.id) {
          return {
            ...emp,
            allocations: selectedProjects.map((proj) => ({ project: proj })),
          };
        }
        return emp;
      })
    );
  };

  const handleSaveAllocationChange = async () => {
    setSpinnerDisplay("flex");
    try {
      const response = await postAPI(`${POST_EMPLOYEE_ALLOCATION}`, {
        start_date: date.start_date,
        end_date: date.end_date,
        employee_allocations: allocationData,
      });
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("Failed to send request, please try again");
      } else {
        fetchData();
        NotificationManager.success("Allocation Saved", "Success");
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    } finally {
      setSpinnerDisplay("none");
    }
  };

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between align-items-center pb-1 my-2">
        <h2 className="text-dark">Add Allocation</h2>
        <div className="btn-groups">
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={handleSaveAllocationChange}
          >
            + Save
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center pb-1 my-2">
        <Typeahead
          labelKey="name"
          onChange={(e) => {
            if (e.length > 0) {
              setDepartment(e[0].id);
            }
          }}
          options={departments}
          ref={ref}
          placeholder="Select Department"
        />
        <button
          type="button"
          className="btn btn-round ms-2"
          onClick={() => {
            ref.current.clear();
            setDepartment(null);
          }}
        >
          <i className="fas fa-close" />
        </button>
      </div>
      <div className="d-flex justify-content-center align-items-center pb-1 my-2">
        <DatePicker
          ref={refDatePicker}
          dateFormat="dd/MM/yy"
          showWeekNumbers
          selectsRange
          startDate={date.start}
          endDate={date.end}
          selected={date.start}
          onChange={(dates) => {
            const [start] = dates;
            const startOfWeek = getStartOfWeek(start);
            const endOfWeek = getEndOfWeek(start);
            setDate({
              ...date,
              start_date: moment(startOfWeek).format("YYYY-MM-DD"),
              start: startOfWeek,
              end_date: moment(endOfWeek).format("YYYY-MM-DD"),
              end: endOfWeek,
            });
            refDatePicker.current.setOpen(false);
          }}
          withPortal
        />
      </div>
      <div className="my-2">
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Email</th>
                  <th>Projects</th>
                </tr>
              </thead>
              <tbody>
                {allocationData.map((emp, index) => (
                  <tr
                    className={index % 2 === 0 ? "even-row" : "odd-row"}
                    key={emp.id}
                  >
                    <td>
                      {emp?.first_name} {emp?.last_name}
                    </td>
                    <td>{emp?.email}</td>
                    <td>
                      <Typeahead
                        labelKey="name"
                        onChange={(e) => handleProjectChange(emp, e)}
                        options={projects}
                        multiple
                        selected={emp?.allocations.map(
                          (allocation) => allocation?.project
                        )}
                        placeholder="Select Projects"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAllocation;
