import axios from "axios";
import { REFRESH_TOKEN } from "./apiUrls";
import { isAccessTokenExpired } from "./helpers";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setTokenCreationTimeStamp,
  clearLocalStorge,
} from "./localStorage";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const setApiHeader = () => {
  axios.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;
};

export const unSetApiHeader = () => {
  delete axios.defaults.headers.common.Authorization;
};

export const postAPI = async (url, body) => {
  try {
    const res = await axios.post(url, body);
    return { data: res?.data?.data, isError: false };
  } catch (err) {
    return {
      error: err?.response?.data?.error,
      data: {},
      isError: true,
    };
  }
};

export const getAPI = async (url, query = null) => {
  setApiHeader();
  try {
    const res = await axios.get(url, {
      params: query,
    });
    return { data: res.data.data, isError: false };
  } catch (err) {
    return {
      error: err.response !== "undefined" ? err.response?.data?.error : err,
      data: {},
      isError: true,
    };
  }
};

export const putAPI = async (url, body) => {
  setApiHeader();
  try {
    const res = await axios.put(url, body);
    return { data: res.data.data, isError: false };
  } catch (err) {
    return {
      error: err.response !== "undefined" ? err.response?.data?.error : err,
      data: {},
      isError: true,
    };
  }
};

export const noAuthGetAPI = async (url, query = null) => {
  try {
    const res = await axios.get(url, {
      params: query,
    });
    return { data: res.data.data, isError: false };
  } catch (err) {
    return {
      error: err.response !== "undefined" ? err.response?.data?.error : err,
      data: {},
      isError: true,
    };
  }
};

export const getApiPdf = async (url, query = null) => {
  setApiHeader();
  try {
    const res = await axios.get(url, {
      params: query,
    });
    return { data: res.data, isError: false };
  } catch (err) {
    return {
      error: err.response?.data?.error,
      data: {},
      isError: true.valueOf,
    };
  }
};

export const patchAPIWrapper = async (url, body) => {
  setApiHeader();
  try {
    const res = await axios.patch(url, body);
    return { data: res.data.data, isError: false };
  } catch (err) {
    return {
      error: err?.response?.data?.message,
      data: {},
      isError: true,
    };
  }
};

export const noAuthPatchAPI = async (url, body) => {
  try {
    const res = await axios.patch(url, body);
    return { data: res.data.data, isError: false };
  } catch (err) {
    return {
      error: err?.response?.data?.error,
      data: {},
      isError: true,
    };
  }
};

export const deleteAPIWrapper = async (url) => {
  setApiHeader();
  try {
    const res = await axios.delete(url);
    return { data: res, error: {}, isError: false };
  } catch (err) {
    return {
      error: err.response.data,
      data: {},
      isError: true,
    };
  }
};

export const updateAuthToken = async () => {
  const expired = isAccessTokenExpired();
  if (expired) {
    const response = await postAPI(REFRESH_TOKEN, {
      refresh: getRefreshToken(),
    });
    if (!response.isError) {
      setAccessToken(response?.data?.access);
      setTokenCreationTimeStamp();
    } else {
      clearLocalStorge();
      window.location = "/login";
    }
  }
};
