import React, { useState, useEffect } from "react";
import { Loader } from "../../CommonComponents";
import { LUNCH } from "../../../utils/apiUrls";
import {
  getAPI,
  setApiHeader,
  postAPI,
  updateAuthToken,
} from "../../../utils/api";
import "./lunchMenu.css";

const LunchMenu = () => {
  const [startDate] = useState(new Date());
  const [lunchItems, setLunchItems] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [lunchId, setLunchId] = useState([]);
  const [responseDisabled, setResponseDisabled] = useState(true);
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");

  const getLunchItems = async () => {
    let lunchMonth = startDate.getMonth() + 1;
    if (startDate.getDate() > 25) {
      if (startDate.getMonth() + 1 === 12) {
        lunchMonth = 1;
      }
      else {
        lunchMonth = startDate.getMonth() + 2;
      }
    }

    setApiHeader();
    const response = await getAPI(`${LUNCH}/?month=${lunchMonth}&year=${startDate.getMonth() + 1 === 12
      ? startDate.getFullYear() + 1
      : startDate.getFullYear()}`);

    if (response.isError) console.log("ERROR");
    else {
      setLunchItems(response.data);
      setSpinnerDisplay("none");
      // const checkIfOptIn = response.data.filter((data) => data.employee_opt_in === true).length;
      if (startDate.getDate() >= process.env.REACT_APP_LUNCH_START
        && startDate.getDate() <= process.env.REACT_APP_LUNCH_END) {
        //         if (checkIfOptIn > 0) setResponseDisabled(true);
        //         else setResponseDisabled(false);
        setResponseDisabled(false);
      }
      else {
        setResponseDisabled(true);
      }
    }
  };

  useEffect(() => {
    updateAuthToken();
    getLunchItems();
  }, []);

  useEffect(() => {
    setCheckedState(lunchItems.map((data) => data.employee_opt_in));
    setLunchId(lunchItems.map((data) => data.id));
  }, [lunchItems]);

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map(
      (item, index) => (index === position
        ? !item
        : item),
    );
    setCheckedState(updatedCheckedState);
  };

  const saveResponses = async () => {
    setSpinnerDisplay("flex");
    const result = lunchId.map((data, index) => (
      { id: data, optIn: checkedState[index] }
    )).filter((data) => (
      data.optIn
        ? data.id
        : null
    )).map((data) => data.id);
    setApiHeader();
    const response = await postAPI(`${LUNCH}/opt-in`, { lunch_menu: result });
    if (!response.error) {
      getLunchItems();
      setSpinnerDisplay("none");
    }
    else console.log("Error");
  };

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Lunch Menu</h2>
        <button
          type="button"
          className="btn btn-round p-2 ms-auto"
          onClick={saveResponses}
          style={{
            display: responseDisabled
              ? "none"
              : "",
          }}
        >
          Save Response
        </button>
      </div>
      <div className="table-container-lunch ">
        <table className="table table-hover" id="data">
          <thead>
            <tr className="table-head">
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Choice</th>
            </tr>
          </thead>
          <tbody>
            {
              lunchItems.length > 0
                ? lunchItems.map((data, index) => (
                  <tr key={data.id}>
                    <td>{data.date}</td>
                    <td>{data.dish.name}</td>
                    <td>
                      <div className="form-check form-switch d-flex justify-content-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={checkedState[index]}
                          onChange={() => handleOnChange(index)}
                          disabled={responseDisabled}
                        />
                      </div>
                    </td>
                  </tr>
                ))
                : <tr><td colSpan="4">No User Data</td></tr>
            }
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: responseDisabled
            ? "none"
            : "",
        }}
        className="w-100 px-2 py-1 text-center mt-5 footer-lunch"
      >
        <h3>Note:</h3>
        <h5>You Can Only Submit A Response Once A Month.</h5>
        <span />
      </div>
    </>
  );
};

export default LunchMenu;
