import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const AutoIncrementCounter = (props) => {
  const { countFrom, timer } = props;
  const navigate = useNavigate();
  const [counter, setCounter] = useState(countFrom);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      }
      else {
        navigate("/login");
      }
    }, timer);
    return () => clearInterval(interval);
  });

  return <span className="mx-1">{counter}</span>;
};

AutoIncrementCounter.propTypes = {
  timer: PropTypes.number,
  countFrom: PropTypes.number,
};

AutoIncrementCounter.defaultProps = {
  timer: 1000,
  countFrom: 5,
};

export default AutoIncrementCounter;
