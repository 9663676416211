import { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import dayGridPlugin from "@fullcalendar/daygrid";
import { NotificationManager } from "react-notifications";
import { Loader } from "../../CommonComponents";
import { parseCheckinData } from "../../../utils/helpers";
import { getAPI } from "../../../utils/api";
import { GET_ATTENDANCE, GET_OFFICE_TIME_TOTAL } from "../../../utils/apiUrls";
import { PRESENCE_STATUS } from "../../../utils/constants";
import "./Attendance.css";

const Attendance = () => {
  const navigate = useNavigate();

  const [attendance, setAttendance] = useState([]);
  const [total, setTotal] = useState({
    in_late_total: 0,
    early_leave_total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const calendar = useRef(null);

  const getAttendanceData = async () => {
    setIsLoading(true);
    try {
      const response = await getAPI(
        `${GET_ATTENDANCE}month=${
          currentDate.getMonth() + 1
        }&year=${currentDate.getFullYear()}`
      );
      if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else if (response?.isError) {
        throw new Error(
          response?.isError || "An error has occurred while fetching data"
        );
      } else {
        setAttendance(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      NotificationManager.error(error.message);
      setIsLoading(false);
    }
  };

  const getTotalOfficeTimeData = async () => {
    try {
      const response = await getAPI(
        `${GET_OFFICE_TIME_TOTAL}month=${
          currentDate.getMonth() + 1
        }&year=${currentDate.getFullYear()}`
      );
      if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else if (response?.isError) {
        throw new Error(
          response?.isError || "An error has occurred while fetching data"
        );
      } else {
        setTotal(response?.data);
      }
    } catch (error) {
      NotificationManager.error(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTotalOfficeTimeData();
    getAttendanceData();
  }, [currentDate]);

  const renderContent = (eventInfo) => {
    const lateStatus = eventInfo.event.extendedProps.fine;
    const status = eventInfo?.event?.extendedProps?.status.toLowerCase();

    return (
      <>
        <p className=" m-0">
          Status: <strong>{eventInfo?.event?.extendedProps?.status}</strong>{" "}
        </p>

        <p
          className=" m-0"
          style={{
            color:
              status === PRESENCE_STATUS.PRESENT && lateStatus
                ? "#f00"
                : "#fff",
          }}
        >
          Check-in:{" "}
          <strong>
            {eventInfo?.event?.extendedProps?.status?.toLowerCase() ===
            PRESENCE_STATUS.PRESENT
              ? `${eventInfo?.event?.extendedProps?.checkin_time ?? "NA"} - ${
                  eventInfo?.event?.extendedProps?.checkout_time ?? "NA"
                }`
              : "NA"}
          </strong>
        </p>
      </>
    );
  };

  return (
    <>
      <Loader displayState={isLoading ? "block" : "none"} message="Loading" />
      <div className=" d-flex justify-content-between align-items-center pb-1 my-2">
        <DatePicker
          dateFormat="MM-yyyy"
          showMonthYearPicker
          selected={currentDate}
          maxDate={new Date()}
          onChange={(date) => {
            setCurrentDate(date);
            calendar.current.getApi().gotoDate(date);
          }}
          withPortal
        />
        <div className="box">
          <div className="title">In Late</div>
          <div className="value">{total?.in_late_total || 0}</div>
        </div>
        <div className="box">
          <div className="title">Early Leave</div>
          <div className="value">{total?.early_leave_total || 0}</div>
        </div>
        <div className="btn-groups">
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("officeTime")}
          >
            <i className="fas fa-plus" /> Office Time Ease
          </button>
        </div>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        height="70vh"
        events={parseCheckinData(attendance)}
        dayHeaderFormat={{ weekday: "short" }}
        eventContent={renderContent}
        ref={calendar}
        headerToolbar={{
          right: "",
          center: "",
          left: "",
          next: "",
        }}
      />
    </>
  );
};

export default Attendance;
