import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  GET_EMPLOYEE_ALLOCATION,
  GET_ALL_DEPARTMENTS,
} from "../../../utils/apiUrls";
import { getAPI } from "../../../utils/api";
import {
  getStartOfWeek,
  getEndOfWeek,
  isAllocationAccess,
} from "../../../utils/helpers";
import { Loader } from "../../CommonComponents";
import "./allocation.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getUserInfo } from "../../../utils/localStorage";

const Allocation = () => {
  const [userInfo] = useState(JSON.parse(getUserInfo()));
  const navigate = useNavigate();
  const ref = React.createRef();
  const refDatePicker = React.createRef();
  const [date, setDate] = useState({
    start_date: moment(getStartOfWeek(new Date())).format("YYYY-MM-DD"),
    end_date: moment(getEndOfWeek(new Date())).format("YYYY-MM-DD"),
    start: getStartOfWeek(new Date()),
    end: getEndOfWeek(new Date()),
  });
  const [spinnerDisplay, setSpinnerDisplay] = useState("none");
  const [data, setData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);

  async function fetchDepartmentData() {
    try {
      const response = await getAPI(`${GET_ALL_DEPARTMENTS}`);
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setDepartments([
          {
            id: null,
            name: "All",
          },
          ...response.data,
        ]);
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    }
  }

  useEffect(() => {
    if (!isAllocationAccess(userInfo)) {
      navigate("/dashboard");
    }
  }, [userInfo]);

  async function fetchData() {
    setSpinnerDisplay("flex");
    try {
      const response = await getAPI(`${GET_EMPLOYEE_ALLOCATION}`, {
        start_date: date.start_date,
        end_date: date.end_date,
        department,
      });
      if (response?.isError) {
        throw new Error(response?.error);
      } else if (!response) {
        throw new Error("An error has occurred while fetching data");
      } else {
        setSpinnerDisplay("none");
        setData(response.data);
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      setSpinnerDisplay("none");
    }
  }

  useEffect(() => {
    fetchDepartmentData();
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [date, department]);

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between align-items-center pb-1 my-2">
        <DatePicker
          ref={refDatePicker}
          dateFormat="dd/MM/yy"
          showWeekNumbers
          selectsRange
          startDate={date.start}
          endDate={date.end}
          selected={date.start}
          onChange={(dates) => {
            const [start] = dates;
            const startOfWeek = getStartOfWeek(start);
            const endOfWeek = getEndOfWeek(start);
            setDate({
              ...date,
              start_date: moment(startOfWeek).format("YYYY-MM-DD"),
              start: startOfWeek,
              end_date: moment(endOfWeek).format("YYYY-MM-DD"),
              end: endOfWeek,
            });
            refDatePicker.current.setOpen(false);
          }}
          withPortal
        />
        <h2 className="text-dark">Allocation</h2>
        <div className="btn-groups">
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("add")}
          >
            + Allocation
          </button>
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("/projects")}
          >
            <i className="fas fa-list" /> Projects
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center pb-1 my-2">
        <Typeahead
          labelKey="name"
          onChange={(e) => {
            if (e.length > 0) {
              setDepartment(e[0].id);
            }
          }}
          options={departments}
          ref={ref}
          placeholder="Select Department"
        />
        <button
          type="button"
          className="btn btn-round ms-2"
          onClick={() => {
            ref.current.clear();
            setDepartment(null);
          }}
        >
          <i className="fas fa-close" />
        </button>
      </div>
      <div className="my-2">
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th>Name </th>
                  <th>Email</th>
                  <th>Projects</th>
                </tr>
              </thead>
              <tbody>
                {data.map((emp, index) => (
                  <tr
                    className={index % 2 === 0 ? "even-row" : "odd-row"}
                    key={emp.id}
                  >
                    <td>
                      {emp?.first_name} {emp?.last_name}
                    </td>
                    <td>{emp?.email.toLowerCase()}</td>
                    <td>
                      {emp?.allocations
                        ?.map((allocation) => allocation?.project?.name)
                        .join(", ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Allocation;
