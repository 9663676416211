import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { postAPI } from "../../../utils/api";
import { FORGOT_PASSWORD } from "../../../utils/apiUrls";
import {
  logo,
  mail,
} from "../../../assets/images";
import "../Auth.css";

const ResetPassword = () => {
  const icons = ["spinner-border", "fa fa-check fa-lg", "fa fa-times fa-lg"];
  const [snackBarIcon, setSnackBarIcon] = useState(icons[0]);
  const [snackBarText, setSnackBarText] = useState("Resetting Password!");
  const [openCollapse, setOpenCollapse] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenCollapse(true);
    const response = await postAPI(FORGOT_PASSWORD, { email });

    if (response.isError) {
      setSnackBarText("Enter a valid Email!");
      setSnackBarIcon(icons[2]);
      setTimeout(() => setOpenCollapse(false), 2000);
    }
    else {
      setSnackBarText("Check Your Email!");
      setSnackBarIcon(icons[1]);
    }
  };

  return (
    <div className="login">
      <div className="tl">
        <img src={logo} alt="logo" className="logo-auth p-4" />
        <div className="login-form position-absolute top-50 start-50 translate-middle p-4">
          <div className="d-flex align-items-start gap-5 text-white">
            <Link to="/login" className="back-navbtn"><i className="fa fa-chevron-left align-self-start" /></Link>
            <h2 className="mb-3 text-white">Password Reset</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 d-flex align-items-center">
              <img src={mail} alt="Email" />
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                className="input-data form-control mx-4 text-white"
                placeholder="Email ID"
                required
              />
            </div>
            <Collapse in={openCollapse} dimension="width">
              <div id="example-collapse-text">
                <div className="mb-3 d-flex align-items-center gap-4  login-spinner">
                  <div className={snackBarIcon} aria-hidden="true" />
                  <h3 className="text-white mx-3">{snackBarText}</h3>
                </div>
              </div>
            </Collapse>
            <div className="mb-3 mx-5 d-flex align-items-center justify-content-center">
              <button type="submit" className="btn btn-primary mx-4">Send Email</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
