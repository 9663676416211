import Modal from "react-bootstrap/Modal";

const ReasonModal = ({ show, text, handleCloseModal }) =>  ( 
            <Modal show={show} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center font-weight-bold">
                        Reason
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {text}
                </Modal.Body>
            </Modal> 
        );

 
export default ReasonModal;