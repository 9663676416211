import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import SimpleSpinner from "../../CommonComponents/SimpleSpinner";
import ReasonModal from "../../CommonComponents/ReasonModal/ReasonModal";
import {
  OFFICE_TIME_REQUESTS,
  GET_EMPLOYEE,
} from "../../../utils/apiUrls";
import { getAPI, postAPI } from "../../../utils/api";
import { filterWeekdays } from "../../../utils/helpers";
import { Loader } from "../../CommonComponents";
import "./officeTime.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

const OfficeTime = () => {
  const navigate = useNavigate();
  const [spinnerDisplay, setSpinnerDisplay] = useState("block");
  const [showModal, setShowModal] = useState({reason: false, del: false, request: false});
  const [reasonTxt, setReasonTxt] = useState("");
  const [isLoading, setIsLoading] = useState({req: false, del: false});
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [employee, setEmployee] = useState([]);

  const officeTimeTypes = [
    {label: "In Late", value: "In Late"},
    {label: "Early Leave", value: "Early Leave"},
  ];


  const [formData, setFormData] = useState({
    type: officeTimeTypes[0].value,
    notes:"",
    to_employee_ids:[],
    date:moment(new Date()).format("YYYY-MM-DD"),
    date_picker:new Date(),
    time_picker:"",
    time:""
  });

  async function fetchData() {
    setSpinnerDisplay("block");
    try {
      const response = await getAPI(`${OFFICE_TIME_REQUESTS}`);
      if (response?.isError) {
        throw new Error(response?.error);
      }
      else if (!response) {
        throw new Error("An error has occurred while fetching data");
      }
      else {
        setLeaveData(response?.data);
        setSpinnerDisplay("none");
      }
    }
    catch (error) {
       NotificationManager.error(error.message,"Error");
    }
  }

  async function fetchEmployee() {
    try {
      const response = await getAPI(`${GET_EMPLOYEE}`);
      if (response?.isError) {
        throw new Error(response?.error || "An Error has occurred while fetching data");
      }
      else if (!response) {
        throw new Error("An error has occurred while fetching data");
      }
      else {
        setEmployee(response.data.map( (emp) => ({
            ...emp,
            fullName: `${emp.first_name} ${emp.last_name}`
          })
         ));
      }
    }
    catch (error) {
      NotificationManager.error(error.message,"Error");
    }
  }

  useEffect(() => {
    fetchData();
    fetchEmployee();
  }, []);

  const dropdownRef = useRef(null);

  const handleOpenModal = () => {
    setShowModal((prevState) => ({...prevState, request: true}));
  };

  const handleCloseModal = () => {
    setShowModal((prevState) => ({...prevState, request: false}));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "to_employee_ids") {
      setShowDropdown(!showDropdown);
    }
    else {
      setFormData( (prevData) => ({ ...prevData, [name]: value }));
      setShowDropdown(false);
    }
  };

  const handleCcChange = (e) => {
    setSelectedOptions(e);
      setFormData( (prevData) => ({
        ...prevData,
        to_employee_ids: e.map( (emp) => emp.id )
      }));
  };

  const handleTypeChange = (e) => {
      const { name, value } = e.target;
      setFormData( (prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown
    const handleClickOutside = (e) => {
      if (
        dropdownRef.current
        && !dropdownRef.current.contains(e.target)
        && e.target.name !== "to_employee_ids"
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.to_employee_ids.length === 0) {
      NotificationManager.error("Choose Some Employee","Error");
      return;
    }
    setIsLoading((prevState) => ({...prevState, req: true}));
    try {
      const response = await postAPI(`${OFFICE_TIME_REQUESTS}`, formData);
      if (response?.isError) {
        throw new Error(response?.error);
      }
      else if (!response) {
        throw new Error("Failed to send request, please try again");
      }
      else {
        fetchData();
        setFormData({
          type: officeTimeTypes[0].value,
          notes:"",
          to_employee_ids:[],
          date:moment(new Date()).format("YYYY-MM-DD"),
          date_picker:new Date(),
          time_picker:"",
          time:""
        });
          setSelectedOptions([]);
          NotificationManager.success("Request sent successfully", "Success");
      }
    }
    catch (error) {
      setFormData({
        type: officeTimeTypes[0].value,
        notes:"",
        to_employee_ids:[],
        date:moment(new Date()).format("YYYY-MM-DD"),
        date_picker:new Date(),
        time_picker:"",
        time:""
      });
      setSelectedOptions([]);
      NotificationManager.error(error.message,"Error");
    }
    finally {
      handleCloseModal();
      setIsLoading((prevState) => ({...prevState, req: false}));
    }
  };


  const itemsPerPage = 5;

  // State for current page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = leaveData
    ? Math.ceil(leaveData.length / itemsPerPage)
    : 0;

  // Function to handle pagination button clicks
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const maxButtons = leaveData.length < 6
    ? 1
    : leaveData.length < 11
      ? 2
      : 3;

  const halfButtons = Math.floor(maxButtons / 2);
  let firstVisiblePage = Math.max(currentPage - halfButtons, 1);
  const lastVisiblePage = Math.min(currentPage + halfButtons, totalPages);

  if (lastVisiblePage - firstVisiblePage < maxButtons - 1) {
    firstVisiblePage = Math.max(1, lastVisiblePage - maxButtons + 1);
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handleClick = (data) => {
    setShowModal((prevState) => ({...prevState, reason: true}));
    setReasonTxt(data);
  };


  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between align-items-center pb-1 my-2">
        <h2 className="text-dark">Office Time Ease</h2>
        <div className="btn-groups">
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={handleOpenModal}
          >
            + Add
          </button>
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("employeeOfficeTime")}
          >
            <i className="fas fa-list" />
            {" "}
            Employee
          </button>
        </div>
      </div>
      <div className="my-2">
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Reason</th> 
                </tr>
              </thead>
              <tbody>
                {leaveData.slice(startIndex, endIndex).map((leave, index) => (
                  <tr className={index % 2 === 0
                    ? "even-row"
                    : "odd-row"}
                    key={leave.id}
                  >
                    <td>{leave?.type}</td>
                    <td>{leave?.date}</td>
                    <td>{leave?.time}</td>
                    <td> 
                      <button className="btn-round" type="button" onClick={() => handleClick(leave?.notes)} >view reason</button> 
                    </td>
                    </tr>
                )
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button
              type="button"
              className="pagination-link"
              disabled={currentPage === 1}
              onClick={() => handlePageClick(currentPage - 1)}
            >
              Previous
            </button>

            {Array.from({ length: maxButtons }).map((_, index) => (
              <button
                type="button"
                className={`pagination-button ${
                  currentPage === firstVisiblePage + index
                    ? "active"
                    : ""
                }`}
                key={firstVisiblePage}
                onClick={() => handlePageClick(firstVisiblePage + index)}
              >
                {firstVisiblePage + index}
              </button>
            ))}

            <button
              type="button"
              className="pagination-link"
              disabled={currentPage === totalPages}
              onClick={() => handlePageClick(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Modal show={showModal?.request} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center font-weight-bold">
            Office Time Ease
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Please fill in your information
          </p>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md={12} xs={12}>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={formData.type}
                  onChange={(e) => handleTypeChange(e)}
                  placeholder="Select Type"
                  required
                >
                  {officeTimeTypes.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={12} xs={12}>
                <Form.Label>CC(required)</Form.Label>
                  <Typeahead
                    id="cc_employees_dropdown"
                    labelKey="fullName"
                    multiple
                    name="to_employee_id"
                    onChange={(e) => handleCcChange(e)}
                    options={employee}
                    placeholder=""
                    selected={selectedOptions}
                  />
             
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md={6} xs={12}>
                <Form.Label className="pe-1" >Date</Form.Label>
                <DatePicker
                  required
                  name="date"
                  showTimeSelect={false}
                  selected={formData.date_picker}
                  onChange={(e) => {
                     handleInputChange({ target:{ name: "date", value:  moment(e).format("YYYY-MM-DD")}});
                     handleInputChange({ target:{ name: "date_picker", value: e}});
                    }}
                  minDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  filterDate={filterWeekdays}
                />
                </Form.Group>
                <Form.Group as={Col} md={6} xs={12}>
                <Form.Label className="p-0" >Time</Form.Label>
                <DatePicker
                  required
                  name="time"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  selected={formData.time_picker}
                  onChange={(e) => {
                     handleInputChange({ target:{ name: "time", value: moment(e).format("HH:mm")}});
                     handleInputChange({ target:{ name: "time_picker", value: e}});
                    }}
                  min={new Date().toISOString().split("T")[0]} // Set min date to today
                />
                <p style={{fontSize:"12px", padding:"2px"}} >Time for Arrival/Departure</p>
              </Form.Group>
            </Row>

            <Form.Group>
              <Form.Label>Reason</Form.Label>
              <Form.Control
                as="textarea"
                name="notes"
                rows={4}
                value={formData.notes}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter reason"
                maxLength={2000}
                required
              />
            </Form.Group>
            <p className="text-center text-danger" style={{fontSize:"12px"}} >
            (Please note that once added, you can&rsquo;t delete or edit it)
          </p>
            <div className="text-center mt-2">
             {isLoading.req
              ? (<SimpleSpinner />)
              : <button className="submit-button" type="submit">
                Submit
              </button>}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ReasonModal show={showModal.reason} text={reasonTxt} handleCloseModal={ () => setShowModal((prevState) => ({...prevState, reason: false})) } />
    </>
  );
};

export default OfficeTime;
