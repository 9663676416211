import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../../CommonComponents";
import { GET_ALL_CLIENTS } from "../../../utils/apiUrls";
import { getAPI, deleteAPIWrapper } from "../../../utils/api";
import "./Clients.css";

const Clients = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");
  const [isLoading, setIsLoading] = useState({ req: false, del: false });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteClientId, setDeleteClientId] = useState(null);

  const getClients = async () => {
    setIsLoading((prevState) => ({ ...prevState, req: true }));
    try {
      const response = await getAPI(GET_ALL_CLIENTS);
      if (response.isError) {
        throw new Error(response.error);
      }
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setSpinnerDisplay("none");
      setIsLoading((prevState) => ({ ...prevState, req: false }));
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleDelete = async () => {
    if (!deleteClientId) return;
    setIsLoading((prevState) => ({ ...prevState, del: true }));
    try {
      const response = await deleteAPIWrapper(
        `${GET_ALL_CLIENTS}/${deleteClientId}`
      );
      if (response?.isError) {
        throw new Error(response?.error);
      }
      setClients(clients.filter((client) => client.id !== deleteClientId));
      NotificationManager.success("Clients deleted successfully", "Success");
    } catch (error) {
      NotificationManager.error(error.message, "Error");
    } finally {
      setSpinnerDisplay("none");
      setIsLoading((prevState) => ({ ...prevState, del: false }));
      setShowDeleteModal(false);
    }
  };

  const openDeleteModal = (id) => {
    setDeleteClientId(id);
    setShowDeleteModal(true);
  };

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Clients</h2>
        <div className="btn-groups">
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("add")}
          >
            + Add
          </button>
          <button
            type="button"
            className="btn btn-round p-2 mb-2 me-2"
            onClick={() => navigate("/projects")}
          >
            <i className="fas fa-list" /> Projects
          </button>
        </div>
      </div>

      <div className="my-2">
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Discord User Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {clients.length > 0 ? (
                  clients.map((data, index) => (
                    <tr
                      key={data.id}
                      className={index % 2 === 0 ? "even-row" : "odd-row"}
                    >
                      <td>{data.name}</td>
                      <td>{data.email}</td>
                      <td>{data.discord_username}</td>
                      <td>
                        <div>
                          <button
                            type="button"
                            className="btn btn-round px-2 py-1 mb-2 me-2"
                            onClick={() =>
                              navigate(`edit/${data.id}`)
                            }
                          >
                            Edit
                          </button>

                          <button
                            type="button"
                            className="btn btn-round bg-danger px-2 py-1 mb-2 me-2"
                            onClick={() => openDeleteModal(data.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No User Data!</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this client?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDelete}
            disabled={isLoading.del}
          >
            {isLoading.del ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Clients;
