export const LOGIN = "employee/login";
export const CURRENT_EMPLOYEE = "employee/me";
export const ALL_EMPLOYEES = "employee/";
export const EXTRA_HOURS = "attendance/extra-time";
export const GET_SALARY = "salary";
export const GET_SALARY_PDF = "salary/pdf";
export const LUNCH = "lunch";
export const FORGOT_PASSWORD = "employee/request-reset-password/";
export const FORGOT_PASSWORD_TOKEN = "employee/password-reset-check-token";
export const PASSWORD_RESET_COMPLETE = "employee/password-reset-complete/";
export const UPDATE_PASSWORD = "employee/update-password";
export const DASHBOARD = "dashboard/";
export const NOTES = "dashboard/todo";
export const REFRESH_TOKEN = "employee/token-refresh/";
export const DELETE_EXTRA_HOURS = "attendance/extra-time/delete";
export const GET_REQUESTS = "attendance/leave-request/";
export const SEND_REQUEST = "attendance/leave-request/";
export const DEL_REQUEST = "attendance/leave-request/";
export const GET_EMPLOYEE = "employee";
export const EMPLOYEE_TOTAL_LEAVE = "attendance/leaves-count/";
export const LEAVE_REQUEST_FROM_YOU = "attendance/leave-request-from-you/";
export const LEAVE_REQUEST_FINE_REMOVAL =
  "attendance/leave-request-fine-removal/";
export const GET_ATTENDANCE = "attendance/calendar?";
export const EMPLOYEE_LEAVE = "attendance/employee-leave-request/";
export const OFFICE_TIME_REQUESTS = "attendance/office-time/";
export const EMPLOYEE_LEAVE_OFFICE_TIME = "attendance/employee-office-time/";
export const GET_OFFICE_TIME_TOTAL = "attendance/office-time-count?";
export const GET_EMPLOYEE_ALLOCATION = "project/allocation";
export const GET_ALL_DEPARTMENTS = "employee/department";
export const GET_ALL_PROJECTS = "project/projects";
export const GET_PROJECT_INFO = "project/projects/";
export const POST_EMPLOYEE_ALLOCATION = "project/allocation";
export const GET_ALL_CLIENTS = "project/clients";
export const GET_CLIENT_INFO = "project/clients/";
export const GET_DRI_EMPLOYEE = "project/dris";
export const ALL_PROJECTS = "project/all";
export const MACHINES = "machine/me";
