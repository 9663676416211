import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { EMPLOYEE_LEAVE } from "../../../utils/apiUrls";
import { getAPI } from "../../../utils/api";
import { Loader } from "../../CommonComponents";
import ReasonModal from "../../CommonComponents/ReasonModal";
import "./employeeLeave.css";

const EmployeeLeave = () => {
  const [spinnerDisplay, setSpinnerDisplay] = useState("block");
  const [leaveData, setLeaveData] = useState([]);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reasonTxt, setReasonTxt] = useState("");


  async function fetchData() {
    try {
      const response = await getAPI(`${EMPLOYEE_LEAVE}`);
      if (response?.isError) {
        throw new Error("Error fetching data");
      }
       else if (!response) {
        throw new Error("An error has occurred while fetching data");
      }
      else {
        setLeaveData(response.data);
        setSpinnerDisplay("none");
      }
    }
    catch (error) {
        NotificationManager.error("Error", error.message);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const itemsPerPage = 5;

  // State for current page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = Math.ceil(leaveData.length / itemsPerPage);

  // Function to handle pagination button clicks
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const maxButtons = leaveData.length < 6
    ? 1
    : leaveData.length < 11
      ? 2
      : 3;

  const halfButtons = Math.floor(maxButtons / 2);
  let firstVisiblePage = Math.max(currentPage - halfButtons, 1);
  const lastVisiblePage = Math.min(currentPage + halfButtons, totalPages);

  if (lastVisiblePage - firstVisiblePage < maxButtons - 1) {
    firstVisiblePage = Math.max(1, lastVisiblePage - maxButtons + 1);
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

    const handleClick = (data) => {
    setShowReasonModal(true);
    setReasonTxt(data);
  };

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Employee Leaves</h2>
      </div>
      <div className="my-2">
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Leave Type</th>
                  <th>From</th>
                  <th>To</th>
                  <th>No of Days</th>
                  <th>Reason</th>
                 </tr>
              </thead>
              <tbody>
                {leaveData.slice(startIndex, endIndex).map((leave, index) => (
                  <tr className={index % 2 === 0
                    ? "even-row"
                    : "odd-row"}
                  >
                    <td>
                      {leave.from_employee.first_name}
                      {" "}
                      {leave.from_employee.last_name}
                    </td>
                    <td>{leave.type}</td>
                    <td>{leave.from_date}</td>
                    <td>{leave.to_date}</td>
                    <td>{leave.duration}</td>
                    <td><button className="btn-round" type="button" onClick={() => handleClick(leave?.notes)} >view reason</button> </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button
              type="button"
              className="pagination-link"
              disabled={currentPage === 1}
              onClick={() => handlePageClick(currentPage - 1)}
            >
              Previous
            </button>

            {Array.from({ length: maxButtons }).map((_, index) => (
              <button
                type="button"
                className={`pagination-button ${
                  currentPage === firstVisiblePage + index
                    ? "active"
                    : ""
                }`}
                onClick={() => handlePageClick(firstVisiblePage + index)}
              >
                {firstVisiblePage + index}
              </button>
            ))}

            <button
              type="button"
              className="pagination-link"
              disabled={currentPage === totalPages}
              onClick={() => handlePageClick(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ReasonModal show={showReasonModal} text={reasonTxt} handleCloseModal={ () => setShowReasonModal(false) } />
    </>
  );
};

export default EmployeeLeave;
