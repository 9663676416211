import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";
import { Loader } from "../../CommonComponents";
import { DASHBOARD, NOTES } from "../../../utils/apiUrls";
import {
  getAPI,
  postAPI,
  setApiHeader,
  deleteAPIWrapper,
  patchAPIWrapper,
  updateAuthToken,
} from "../../../utils/api";
import "./Dashboard.css";

const Dashboard = () => {
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");
  const [icon, setIcon] = useState("no-icon");
  const [startDate] = useState(new Date());
  const [companyAnnouncements, setCompanyAnnouncements] = useState("");
  const [allocatedProjects, setAllocatedProjects] = useState("");
  // const [totalExtraHours, setTotalExtraHours] = useState(0);
  const [lunch, setLunch] = useState({
    dish: "",
    status: false,
    option: "",
  });
  const [stickyNotes, setStickyNotes] = useState([]);
  const [addNotes, setAddNotes] = useState({
    title: "",
    description: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState({
    show: false,
    id: "",
    title: "",
    description: "",
  });
  const [modalDelete, setModalDelete] = useState({
    message: "Are You Sure You Want To Delete This?",
    show: false,
    id: "",
  });

  const handleCloseUpdate = () => {
    setModalUpdate({
      ...modalUpdate,
      show: false,
    });
  };
  const handleCloseDelete = () => {
    setModalDelete({
      ...modalDelete,
      show: false,
    });
  };
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const fetchData = async () => {
    const response = await getAPI(DASHBOARD, {
      month: startDate.getMonth() + 1,
      year: startDate.getFullYear(),
    });
    if (response?.isError)
      NotificationManager.error(
        response?.isError || "Error while fetching data"
      );
    else {
      setLunch({
        ...lunch,
        dish: response?.data[0].today_lunch?.dish,
        status: response?.data[0].today_lunch?.status,
        option: response?.data[0].today_lunch?.status ? "Yes" : "No",
      });
      setCompanyAnnouncements(response?.data[0]?.company_anoucments);
      setStickyNotes(response?.data[0]?.sticky_notes.reverse());
      setAllocatedProjects(response?.data[0].allocated_projects);
      // setTotalExtraHours(response.data[0].extra_hours);
      setSpinnerDisplay("none");
    }
  };

  const handleStickyNotesFormData = (e) => {
    const { value, name } = e.target;
    setAddNotes({ ...addNotes, [name]: value });
  };

  const AddNotesForm = async (e) => {
    e.preventDefault();
    setIcon("spinner-border spinner-border-sm");
    setApiHeader();
    const response = await postAPI(`${NOTES}`, addNotes);

    if (response?.isError) {
      NotificationManager.error(
        response?.isError || "Error while fetching data"
      );
    } else {
      setIcon("fa fa-check fa-sm");
      fetchData();
      setAddNotes({ ...addNotes, title: "", description: "" });
      handleClose();
      setIcon("no-icon");
    }
  };

  useEffect(() => {
    updateAuthToken();
    fetchData();
  }, []);

  const deleteNotes = async (id) => {
    setIcon("spinner-border spinner-border-sm");
    const response = await deleteAPIWrapper(`${NOTES}/${id}`);
    if (!response.isError) {
      setIcon("fa fa-check fa-sm");
      setModalDelete({ ...modalDelete, message: "Deleted" });
      setTimeout(() => {
        setModalDelete({ ...modalDelete, show: false });
      }, 200);
      setIcon("no-icon");
      fetchData();
    } else {
      setModalDelete({ ...modalDelete, message: "Something went wrong!" });
    }
  };

  const handleDeletion = (id) => {
    setModalDelete({ ...modalDelete, show: true, id });
  };

  const UpdateNotesForm = async (e) => {
    e.preventDefault();
    setIcon("spinner-border spinner-border-sm");
    const response = await patchAPIWrapper(`${NOTES}/${modalUpdate.id}`, {
      title: modalUpdate.title,
      description: modalUpdate.description,
      is_complete: modalUpdate.is_complete,
    });
    if (!response.isError) {
      setIcon("fa fa-check fa-lg");
      setTimeout(() => {
        setModalUpdate({ ...modalUpdate, show: false });
      }, 400);
      setIcon("no-icon");
      fetchData();
    }
  };

  const handleUpdate = (id, title, description, is_complete) => {
    setModalUpdate({
      ...modalUpdate,
      show: true,
      id,
      title,
      description,
      is_complete,
    });
  };

  const handleTaskComplete = async (data) => {
    setSpinnerDisplay("flex");
    const response = await patchAPIWrapper(`${NOTES}/${data.id}`, {
      title: data.title,
      description: data.description,
      is_complete: true,
    });
    if (!response.isError) {
      fetchData();
    }
  };

  const handleUpdateFormDataInput = (e) => {
    const { value, name } = e.target;
    setModalUpdate({ ...modalUpdate, [name]: value });
  };

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="dashboard-container mt-3 d-flex justify-content-sm-center justify-content-lg-between flex-wrap-reverse gap-5">
        <div className="d-flex flex-column justify-content-evenly gap-3">
          <div className="dashboard-card px-3 rounded">
            <h3 className="text-center">Company Announcements</h3>
            {companyAnnouncements.length > 0 ? (
              companyAnnouncements.map((data) => (
                <p key={data?.annoucment}>{data?.annoucment}</p>
              ))
            ) : (
              <p>No Announcements</p>
            )}
          </div>
          <div className="dashboard-card px-3 rounded">
            <h3 className="text-center">Today&apos;s Lunch Menu</h3>
            <ul className="list-unstyled">
              <li>
                <span>Dish : </span>
                {lunch.dish}
              </li>
              <li>
                <span>Eating : </span>
                {lunch.dish ? lunch.option : ""}
              </li>
            </ul>
          </div>
          <div className="dashboard-card px-3 rounded">
            <h3 className="text-center">Allocated Projects</h3>
            <ul className="list-unstyled">
              {allocatedProjects.length > 0 ? (
                allocatedProjects.map((project) => (
                  <li key={project.project__id}>
                    <span>Project Name : </span>
                    {project.project__name ? project.project__name : ""}
                  </li>
                ))
              ) : (
                <li>
                  <p>No Project Allocated</p>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-center">Sticky Notes</h3>
          <div className="sticky-notes-body rounded">
            <button
              type="button"
              onClick={handleShow}
              className="px-3 pt-2 sticky-notes-btn d-flex justify-content-start align-items-center"
            >
              <i className="fa fa-plus-square fa-2x text-white" />
            </button>
            <div className="text-area px-3">
              {stickyNotes.length > 0 ? (
                stickyNotes.map((data) => (
                  <div
                    className="d-flex flex-column align-items-end border border-2 my-1 rounded"
                    key={data.title}
                  >
                    <div className="border-bottom w-100 d-flex  justify-content-between">
                      <span className="px-2 text-white">{data.title}</span>
                      <div>
                        {!data.is_complete ? (
                          <button
                            type="button"
                            className="sticky-notes-btn text-end border-start"
                            onClick={() => handleTaskComplete(data)}
                          >
                            <i className="fa fa-check fa-lg px-2 text-white" />
                          </button>
                        ) : null}
                        <button
                          type="button"
                          className="sticky-notes-btn text-end border-start"
                          onClick={() =>
                            handleUpdate(
                              data.id,
                              data.title,
                              data.description,
                              data.is_complete
                            )
                          }
                        >
                          <i className="fa fa-pencil-square-o fa-lg px-2 text-white" />
                        </button>
                        <button
                          type="button"
                          className="sticky-notes-btn text-end border-start"
                          onClick={() => handleDeletion(data.id)}
                        >
                          <i className="fa fa-times fa-lg px-2 text-white" />
                        </button>
                      </div>
                    </div>
                    <textarea
                      className="my-1 sticky-notes "
                      rows="4"
                      cols="33"
                      readOnly
                      value={data.description}
                    />
                  </div>
                ))
              ) : (
                <div className="d-flex flex-column align-items-end border border-2 my-1 rounded">
                  <span className="border-bottom w-100 text-center text-white">
                    No User Data!
                  </span>
                  <textarea
                    className="my-1 sticky-notes text-center"
                    rows="1"
                    cols="33"
                    readOnly
                    value="No User Data"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal size="lg" show={showModal} onHide={handleClose} centered>
          <form onSubmit={(e) => AddNotesForm(e)}>
            <Modal.Header closeButton>
              <h4>Add Notes</h4>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-container">
                <h5>Title</h5>
                <textarea
                  name="title"
                  value={addNotes.title}
                  onChange={handleStickyNotesFormData}
                  className="add-notes text-dark"
                  rows="1"
                  required
                />
                <h5>Description</h5>
                <textarea
                  name="description"
                  value={addNotes.description}
                  onChange={handleStickyNotesFormData}
                  className="add-notes text-dark"
                  rows="8"
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Add Notes
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal show={modalDelete.show} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <h4>Delete Notes</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-container">
              <p>{modalDelete.message}</p>
            </div>
            <button
              type="button"
              className="btn btn-primary float-end"
              onClick={() => deleteNotes(modalDelete.id)}
            >
              <div className={icon} role="status">
                <span />
              </div>
              <span>Delete Notes</span>
            </button>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={modalUpdate.show}
          onHide={handleCloseUpdate}
          centered
        >
          <form onSubmit={UpdateNotesForm}>
            <Modal.Header closeButton>
              <h4>Add Notes</h4>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-container">
                <h5>Title</h5>
                <textarea
                  name="title"
                  value={modalUpdate.title}
                  onChange={handleUpdateFormDataInput}
                  className="add-notes text-dark"
                  rows="1"
                  required
                />
                <h5>Description</h5>
                <textarea
                  name="description"
                  value={modalUpdate.description}
                  onChange={handleUpdateFormDataInput}
                  className="add-notes text-dark"
                  rows="8"
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary d-flex justify-content-between align-items-center gap-1"
              >
                <div className={icon} role="status">
                  <span />
                </div>
                <span>Update Notes</span>
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default Dashboard;
