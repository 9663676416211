import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getAccessToken } from "../utils/localStorage";

const PublicRoutes = () => (
  !getAccessToken()
    ? <Outlet />
    : <Navigate to="/dashboard" />
);

export default PublicRoutes;
