import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../CommonComponents";
import { MACHINES } from "../../../utils/apiUrls";
import { getAPI } from "../../../utils/api";
import "./Machines.css";

const Machines = () => {
  const navigate = useNavigate();
  const [machines, setMachines] = useState([]);
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");

  const getMachines = async () => {
    try {
      const response = await getAPI(MACHINES);
      if (response.isError) {
        throw new Error(response.error);
      }
      const assignedMachines = response.data.assigned_machines || [];
      const machineData = assignedMachines.map((item) => ({
        ...item.machine,
        assign_date: item.assign_date,
      }));
      setMachines(machineData);
    } catch (error) {
      console.error("Error fetching machines:", error);
    } finally {
      setSpinnerDisplay("none");
    }
  };

  useEffect(() => {
    getMachines();
  }, []);

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex justify-content-between pb-1 my-2">
        <h2 className="text-dark">Machines</h2>
      </div>

      <div className="my-2">
        <div className="leave-table-container">
          <div className="leave-table">
            <table>
              <thead>
                <tr>
                  <th scope="col">Serial Number</th>
                  <th scope="col">Type</th>
                  <th scope="col">Make</th>
                  <th scope="col">Ram</th>
                  <th scope="col">Storage</th>
                  <th scope="col">Assign Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {machines.length > 0 ? (
                  machines.map((machine, index) => (
                    <tr
                      key={machine.id}
                      className={index % 2 === 0 ? "even-row" : "odd-row"}
                    >
                      <td>{machine.serial_no}</td>
                      <td>{machine.type}</td>
                      <td>{machine.make}</td>
                      <td>{machine.ram}</td>
                      <td>{machine.storage}</td>
                      <td>{machine.assign_date}</td>
                      <td>
                        <div>
                          <button
                            type="button"
                            className="btn btn-round px-2 py-1 mb-2 me-2"
                            onClick={() =>
                              navigate(`/machine/me/${machine.id}`, {
                                state: { machine },
                              })
                            }
                          >
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No Machine Data!</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Machines;
