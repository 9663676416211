import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import DatePicker from "react-datepicker";
import {
  Modal,
  Collapse,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Loader } from "../../CommonComponents";
import {
  ALL_EMPLOYEES,
  EXTRA_HOURS,
  DELETE_EXTRA_HOURS,
} from "../../../utils/apiUrls";
import {
  deleteAPIWrapper,
  getAPI,
  postAPI,
  setApiHeader,
  updateAuthToken,
} from "../../../utils/api";
import { getUserInfo } from "../../../utils/localStorage";
import { user as userIcon } from "../../../assets/images";
import "./ExtraHours.css";

const ExtraHours = () => {
  const icons = ["spinner-border", "fa fa-check fa-lg", "fa fa-times fa-lg"];
  const [month] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [extraHourEvent, setExtraHourEvent] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [uiEvents, setUiEvents] = useState({
    openCollapse: false,
    showModal: false,
    spinnerDisplay: "flex",
    dropdownSelect: "Select User",
    profileImage: null,
    showModalEvents: false,
  });
  const [snackbar, setSnackBar] = useState({
    snackBarIcon: icons[0],
    snackBarText: "Adding Hours ...",
  });
  const [extraHoursForm, setExtraHoursForm] = useState({
    number_of_hours: "",
    report_to: "",
    date: `${startDate.getFullYear()}-${startDate.getMonth() < 9
      ? `0${startDate.getMonth() + 1}`
      : startDate.getMonth() + 1}-${startDate.getDate() < 10
      ? `0${startDate.getDate()}`
      : startDate.getDate()}`,
    notes: "",
  });
  const [showEventDetails, setShowEventDetails] = useState({
    id: "",
    number_of_hours: "",
    date: "",
    description: "",
    designation: "",
  });
  const {
    id,
    number_of_hours,
    date,
    description,
    designation,
  } = showEventDetails;

  const handleClose = () => setUiEvents(
    {
      ...uiEvents,
      openCollapse: false,
      showModal: false,
    },
  );

  const handleShow = () => setUiEvents(
    {
      ...uiEvents,
      showModal: true,
    },
  );
  const handleCloseModalEvents = () => setUiEvents(
    {
      ...uiEvents,
      showModalEvents: false,
    },
  );
  const handleShowModalEvents = () => setUiEvents(
    {
      ...uiEvents,
      showModalEvents: true,
    },
  );

  const handleFormData = (e) => {
    const { value, name } = e.target;
    setExtraHoursForm({ ...extraHoursForm, [name]: value });
  };

  const fetchData = async () => {
    const response = await getAPI(ALL_EMPLOYEES);

    if (response.isError) console.log("ERROR");
    else {
      setUserInfo(response.data);
    }
  };

  const renderExtraHours = async (currMonth, year) => {
    setApiHeader();
    const response = await getAPI(`${EXTRA_HOURS}/calendar`, { currMonth, year });

    if (!response.isError) return response.data;
    return "Error From Api";
  };

  const eventSet = async (dateInfo) => {
    const test = renderExtraHours(dateInfo.start.getMonth() + 1, dateInfo.start.getFullYear());
    await test.then((response) => {
      response.forEach((data) => {
        data.eventID = data.id;
        data.eventDate = data.date;
        data.description = data.notes;
        data.title = `${data.number_of_hours} ${data.number_of_hours > 1
          ? "hours"
          : "hour"}`;
        data.color = data.status === "0"
          ? "#000"
          : "#F8971C";
      });
      setExtraHourEvent(response);
      setUiEvents({ ...uiEvents, spinnerDisplay: "none" });
    });
  };

  const sendHours = async () => {
    setUiEvents({ ...uiEvents, openCollapse: true });
    setApiHeader();
    if (extraHoursForm.notes.length !== 0) {
      const response = await postAPI(EXTRA_HOURS, extraHoursForm);
      if (response.isError) {
        setSnackBar({ ...snackbar, snackBarIcon: icons[2], snackBarText: response.error });
      }
      else {
        eventSet({ start: startDate });
        setSnackBar({ ...snackbar, snackBarIcon: icons[1], snackBarText: "Hours Added!" });
        setTimeout(() => setUiEvents({ ...uiEvents, openCollapse: false, showModal: false }), 1000);
      }
    }
    else {
      setSnackBar({ ...snackbar, snackBarIcon: icons[2], snackBarText: "Make sure no field is empty!" });
    }
  };

  const handleExtraHoursEvent = (info) => {
    handleShowModalEvents();
    const eventInfo = info.event._def.extendedProps;
    setShowEventDetails({
      ...showEventDetails,
      id: eventInfo.eventID,
      number_of_hours: eventInfo.number_of_hours,
      date: eventInfo.eventDate,
      description: eventInfo.description,
      designation: eventInfo.status,
    });
  };

  const deleteExtraHours = async () => {
    const response = await deleteAPIWrapper(`${DELETE_EXTRA_HOURS}/${id}`);
    if (!response.isError) {
      eventSet({ start: month });
      setTimeout(() => handleCloseModalEvents(), 500);
    }
  };

  useEffect(() => {
    updateAuthToken();
  }, []);

  return (
    <>
      <Loader displayState={uiEvents.spinnerDisplay} />
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        height="70vh"
        // events={extraHourEvent}
        showNonCurrentDates={false}
        dayHeaderFormat={{ weekday: "short" }}
        headerToolbar={{
          right: "extraTimeRequestButton",
          center: "prev,title,next",
          left: "addHoursButton",
        }}
        customButtons={{
          addHoursButton: {
            text: "Add Hours",
            click: () => {
              fetchData();
              handleShow();
            },
          },
          extraTimeRequestButton: {
            text: "Extra time requests",
            click: () => {
              // navigate("../extraHoursRequest");
            },
          },
        }}
        datesSet={(dateInfo) => {
          eventSet(dateInfo);
          month.setMonth(dateInfo.start.getMonth());
          setUiEvents({ ...uiEvents, spinnerDisplay: "flex" });
        }}
        eventClick={(info) => handleExtraHoursEvent(info)}
      />
      <div className="pop1">
        <Modal show={uiEvents.showModal} onHide={handleClose} center="true">
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="d-flex flex-column align-items-center gap-4">
              <div className="px-5 d-flex">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={(change) => {
                    setStartDate(change);
                    setExtraHoursForm(
                      {
                        ...extraHoursForm,
                        date: `${change.getFullYear()}-${change.getMonth() < 9
                          ? `0${change.getMonth() + 1}`
                          : change.getMonth() + 1}-${change.getDate() < 10
                          ? `0${change.getDate()}`
                          : change.getDate()}`,
                      },
                    );
                  }}
                />
              </div>
              <form className="d-flex flex-column gap-3 py-0" onSubmit={(e) => e.preventDefault()}>
                <div className="d-flex gap-4">
                  <span className="mx-2">Add Hours</span>
                  <input
                    className="input-hour p-2"
                    type="number"
                    name="number_of_hours"
                    onChange={handleFormData}
                    required
                    pattern="0|[1-9]\d*"
                  />
                </div>
                <div className="d-flex gap-4">
                  <span className="mx-1">Description</span>
                  <textarea
                    rows="4"
                    name="notes"
                    onChange={handleFormData}
                    required
                  />
                </div>
                <div className="d-flex gap-4">
                  <span className="mx-2"><strong>Report To</strong></span>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      uiEvents.dropdownSelect === "Select User"
                        ? uiEvents.dropdownSelect
                        : (
                          <div className="d-flex align-items-center">
                            <img
                              className="extra-hour-icon px-1"
                              src={
                                uiEvents.profileImage === null
                                  ? userIcon
                                  : uiEvents.profileImage
                              }
                              alt="userIcon"
                            />
                            <span>{uiEvents.dropdownSelect}</span>
                          </div>
                        )
                    }
                    onSelect={(e) => {
                      setExtraHoursForm({ ...extraHoursForm, report_to: e });
                      const reportToUser = userInfo.filter(
                        (data) => data.id === parseInt(e, 10),
                      )[0];
                      const {
                        first_name,
                        last_name,
                        status,
                        profile_image,
                      } = reportToUser;
                      setUiEvents({ ...uiEvents, dropdownSelect: `${first_name} ${last_name} - ${status}`, profileImage: profile_image });
                    }}
                  >
                    {
                      userInfo.filter(
                        (data) => data.id !== JSON.parse(
                          getUserInfo(),
                        ).id,
                      ).map(
                        (mm) => (
                          <Dropdown.Item eventKey={mm.id} key={mm.first_name}>
                            <div className="dropdown-container d-flex">
                              <img
                                className="extra-hour-icon px-1"
                                src={
                                  mm.profile_image === null
                                    ? userIcon
                                    : mm.profile_image
                                }
                                alt="userIcon"
                              />
                              <div>
                                <p className="my-1 mx-2">
                                  {
                                    `${mm.first_name.charAt(0).toUpperCase() + mm.first_name.slice(1)} ${mm.last_name.charAt(0).toUpperCase() + mm.last_name.slice(1)}`
                                  }
                                </p>
                                <p className="my-1 mx-2">
                                  {mm.status}
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ),
                      )
                    }
                  </DropdownButton>
                </div>
                <Collapse in={uiEvents.openCollapse} dimension="width">
                  <div id="example-collapse-text">
                    <div className="mb-3 d-flex align-items-center justify-content-center gap-4 hours-spinner">
                      <div className={snackbar.snackBarIcon} aria-hidden="true" />
                      <h3 className=" mx-3">{snackbar.snackBarText}</h3>
                    </div>
                  </div>
                </Collapse>
                <button type="submit" onClick={sendHours} className="submit-hours align-self-center">
                  Submit
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="d-flex flex-column w-100 footer-cal">
        <p className="footer-text my-0">
          Total Work Hours for the month of
          {month.toLocaleDateString("en-US", { month: "long" })}
        </p>
        <div className="d-flex justify-content-center gap-5">
          <p className="footer-text txt-size">
            <span>Approved : </span>
            {extraHourEvent.filter((data) => data.status === "1").reduce((prev, nex) => prev + nex.number_of_hours, 0)}
            <span> Hours</span>
          </p>
          <p className="footer-text txt-size">
            <span>Un-Approved : </span>
            {extraHourEvent.filter((data) => data.status !== "1").reduce((prev, nex) => prev + nex.number_of_hours, 0)}
            <span> Hours</span>
          </p>
        </div>
      </div>
      <Modal
        show={uiEvents.showModalEvents}
        onHide={handleCloseModalEvents}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h4>Extra Hours</h4>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td className="bg-zweidevs">Date</td>
                <td>{date}</td>
                <td className="bg-zweidevs">Number Of Hours</td>
                <td>
                  {number_of_hours}
                  {
                    number_of_hours !== 1
                      ? " Hours"
                      : " Hour"
                  }
                </td>
              </tr>
              <tr>
                <td className="bg-zweidevs">Status</td>
                <td>
                  {
                    designation === "1"
                      ? "Approved"
                      : "Un-Approved"
                  }
                </td>
                <td className="bg-zweidevs">Report To</td>
                <td>
                  <img src={userIcon} alt="userImg" height="30px" />
                  <span>Suppose there&apos;s data</span>
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Description</h5>
          <textarea value={description} className="no-resize px-2" rows="5" readOnly />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={deleteExtraHours}>Delete</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExtraHours;
