import React, { useState, useEffect } from "react";
import { Link, useNavigate, Outlet, NavLink } from "react-router-dom";
import { Collapse, Dropdown, DropdownButton } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { Loader } from "../CommonComponents";
import { setUserInfo, clearLocalStorge } from "../../utils/localStorage";
import { CURRENT_EMPLOYEE } from "../../utils/apiUrls";
import { getAPI, updateAuthToken, unSetApiHeader } from "../../utils/api";
import {
  logo,
  dashboard,
  // extraHours,
  salDetails,
  lunchMenu,
  leaveMenu,
  time,
  user,
  attendance,
  addPeople,
} from "../../assets/images";
import "./MainComponent.css";
import { isAllocationAccess } from "../../utils/helpers";

const MainComponent = () => {
  const [spinnerDisplay, setSpinnerDisplay] = useState("none");
  const navigate = useNavigate();
  const [icons, setIcons] = useState([
    { name: "Dashboard", img: dashboard, path: "dashboard" },
    { name: "Salary Details", img: salDetails, path: "salaryDetails" },
    { name: "Lunch Menu", img: lunchMenu, path: "lunchMenu" },
    { name: "Leave Management", img: leaveMenu, path: "leaveManagement" },
    { name: "Attendance", img: attendance, path: "attendance" },
  ]);
  const [dateNav, setDateNav] = useState(new Date());
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(true);

  const fetchData = async () => {
    const response = await getAPI(CURRENT_EMPLOYEE);

    if (response.isError || response.data === undefined)
      NotificationManager.error("Data not found", "Error");
    else {
      setUserData(response.data);
      setUserInfo(JSON.stringify(response.data));
    }
  };

  useEffect(() => {
    if (isAllocationAccess(userData)) {
      setIcons([
        ...icons,
        { name: "Allocation", img: addPeople, path: "allocation" },
      ]);
    }
  }, [userData]);

  useEffect(() => {
    setInterval(() => setDateNav(new Date()), 10000);
    updateAuthToken();
    fetchData();
  }, []);

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="d-flex">
        <Collapse in={open} dimension="width">
          <div>
            <nav id="sidebar" className="side-nav pt-4">
              <div className="d-flex justify-content-center">
                <img className="sidebar-logo" src={logo} alt="Logo" />
              </div>
              <ul className="list-unstyled pt-2 bor-orange mt-3 d-flex flex-column">
                {icons.map((sideIcons) => (
                  <li key={sideIcons.name}>
                    <NavLink
                      to={sideIcons.path}
                      className="d-flex flex-column align-items-center p-2 icoo"
                    >
                      <img
                        className="nav-icons"
                        src={sideIcons.img}
                        alt={sideIcons.name}
                      />
                      <p className="text-white sidebar-icon-text">
                        {sideIcons.name}
                      </p>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </Collapse>
        <div className="w-100 pl-2">
          <div className="topnav my-2 d-flex justify-content-between">
            <button
              type="button"
              className="btn-sidebar align-self-center"
              onClick={() => setOpen(!open)}
            >
              <i className="fa fa-bars fa-lg" />
            </button>
            <div className="nav-time ">
              <img src={time} className="iconHeight" alt="clock" />
              <div>
                <p className="time">
                  {dateNav.toLocaleTimeString(navigator.language, {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
                <p className="orange-italic">
                  <i>
                    {dateNav.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </i>
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center px-3">
              <div className="px-2">
                <DropdownButton
                  id="dropdown-basic-button"
                  title={<i className="fa fa-bars fa-lg" />}
                  autoClose
                >
                  <Dropdown.Item
                    onClick={() => {
                      setSpinnerDisplay("flex");
                      navigate("/profile");
                      setTimeout(() => setSpinnerDisplay("none"), 300);
                    }}
                  >
                    <span className="dropdown-text">My Profile</span>
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      setSpinnerDisplay("flex");
                      navigate("/updatePassword");
                      setTimeout(() => setSpinnerDisplay("none"), 300);
                    }}
                  >
                    Update Password
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      setSpinnerDisplay("flex");
                      navigate("/machine/me");
                      setTimeout(() => setSpinnerDisplay("none"), 300);
                    }}
                  >
                    Assigned Machines
                  </Dropdown.Item>

                  <Link
                    to="/login"
                    className="dropdown-item"
                    onClick={() => {
                      clearLocalStorge();
                      unSetApiHeader();
                    }}
                  >
                    Logout
                  </Link>
                </DropdownButton>
              </div>
              <img
                className="navbar-icon"
                src={
                  userData?.profile_image === null
                    ? user
                    : userData.profile_image
                }
                alt="userIcon"
              />
              <div className="name-designation px-2">
                <p className="time">{`${userData.first_name} ${userData.last_name}`}</p>
                <p className="orange-italic">{userData.status}</p>
              </div>
            </div>
          </div>
          <div className="content mx-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainComponent;
