import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { UPDATE_PASSWORD } from "../../../utils/apiUrls";
import { patchAPIWrapper, updateAuthToken } from "../../../utils/api";
import { pwd, access } from "../../../assets/images";
import "./UpdatePassword.css";

const UpdatePasword = () => {
  const navigate = useNavigate();
  const icons = ["spinner-border", "fa fa-check fa-lg", "fa fa-times fa-lg"];
  const [isPasswordShown, setIsPasswordSHown] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [snackBar, setSnackBar] = useState({
    snackBarIcon: icons[0],
    snackBarText: "Updating Password!",
  });
  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const togglePassword = () => {
    setIsPasswordSHown((pass) => !pass);
  };

  const handleFormData = (e) => {
    const { value, name } = e.target;
    setPassword({ ...password, [name]: value });
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    if (openCollapse) {
      setSnackBar({ ...snackBar, snackBarIcon: icons[0], snackBarText: "Updating Password!" });
    }
    else {
      setOpenCollapse(true);
    }
    const response = await patchAPIWrapper(`${UPDATE_PASSWORD}/`, password);
    if (response.isError) {
      if (response.error === "Follow the password set rules") {
        setSnackBar({ ...snackBar, snackBarIcon: icons[2], snackBarText: "Password Length Should Be Around 8-10 Characters Containing At Least One Special Character!" });
      }
      else {
        setSnackBar({ ...snackBar, snackBarIcon: icons[2], snackBarText: response.error });
      }
    }
    else {
      setSnackBar({ ...snackBar, snackBarIcon: icons[1], snackBarText: "Password Updated!" });
      setTimeout(() => {
        navigate("/dashboard");
      }, 400);
    }
  };

  useEffect(() => {
    updateAuthToken();
  }, []);

  return (
    <div className="d-flex justify-content-center bg-dark rounded my-3">
      <div className="update-pwd p-4 bg-dark my-5">
        <h2 className="mb-3">Password Update</h2>
        <form onSubmit={handleFormSubmission}>
          <div className="mb-3 d-flex align-items-center">
            <img src={access} alt="Password" />
            <input
              name="old_password"
              value={password.old_password}
              onChange={handleFormData}
              type={isPasswordShown
                ? "text"
                : "password"}
              className="input-data form-control mx-4 text-white"
              placeholder="Old Password"
              required
            />
          </div>
          <div className="mb-3 d-flex align-items-center">
            <img src={pwd} alt="Password" />
            <input
              name="new_password"
              value={password.new_password}
              onChange={handleFormData}
              type={isPasswordShown
                ? "text"
                : "password"}
              className="input-data form-control mx-4 text-white"
              placeholder="New Password"
              required
            />
          </div>
          <div className="mb-3 d-flex align-items-center">
            <img src={pwd} alt="Password" />
            <input
              name="confirm_password"
              value={password.confirm_password}
              onChange={handleFormData}
              type={isPasswordShown
                ? "text"
                : "password"}
              className="input-data form-control mx-4 text-white"
              placeholder="Confirm Password"
              required
            />
          </div>
          <div className="mb-3 d-flex align-items-center justify-content-start mx-4">
            <div className="mx-5 d-flex gap-2">
              <input
                id="checkbox"
                type="checkbox"
                checked={isPasswordShown}
                onChange={togglePassword}
              />
              <span className="text-white">Show Password</span>
            </div>
          </div>
          <Collapse in={openCollapse} dimension="width">
            <div id="example-collapse-text">
              <div className="mb-3 d-flex align-items-center gap-4  login-spinner">
                <div className={snackBar.snackBarIcon} aria-hidden="true" />
                <h5 className="text-white mx-3 text-center">{snackBar.snackBarText}</h5>
              </div>
            </div>
          </Collapse>
          <div className="mb-3 mx-5 d-flex align-items-center justify-content-between">
            <button type="submit" className="btn btn-primary mx-4">Update Password</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePasword;
