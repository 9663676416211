import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getAccessToken } from "../utils/localStorage";

const PrivateRoutes = () => (
  getAccessToken()
    ? <Outlet />
    : <Navigate to="/login" />
);

export default PrivateRoutes;
