import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { AutoIncrementCounter, Loader } from "../../CommonComponents";
import { noAuthGetAPI, noAuthPatchAPI, unSetApiHeader } from "../../../utils/api";
import { FORGOT_PASSWORD_TOKEN, PASSWORD_RESET_COMPLETE } from "../../../utils/apiUrls";
import { containsSpecialChars } from "../../../utils/helpers";
import {
  logo,
  access,
  pwd,
} from "../../../assets/images";
import "../Auth.css";

const ResetPasswordConfirm = () => {
  const navigate = useNavigate();
  const icons = ["spinner-border", "fa fa-check fa-lg", "fa fa-times fa-lg"];
  const { id, resetToken } = useParams();
  const resetPasswordHash = `${id}/${resetToken}`;
  const [isPasswordShown, setIsPasswordSHown] = useState(false);
  const [spinnerDisplay, setSpinnerDisplay] = useState("flex");
  const [openCollapse, setOpenCollapse] = useState(false);
  const [snackBar, setSnackBar] = useState({
    snackBarIcon: icons[0],
    snackBarText: "Updating Password!",
  });
  const [password, setPassword] = useState({
    newPassword: "",
    confirmNewPassword: "",
    isPasswordChanged: false,
  });
  const [authStatus, setAuthStatus] = useState({
    isLoggedin: false,
    display: false,
  });

  const togglePassword = () => {
    setIsPasswordSHown((pass) => !pass);
  };

  const resetTokenValidity = async () => {
    unSetApiHeader();
    const response = await noAuthGetAPI(`${FORGOT_PASSWORD_TOKEN}/${resetPasswordHash}/`);
    setSpinnerDisplay("none");
    if (response.isError) {
      setAuthStatus({ ...authStatus, display: true });
    }
    else {
      setAuthStatus({ ...authStatus, isLoggedIn: true, display: true });
    }
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    setOpenCollapse(true);
    setSnackBar({ ...snackBar, snackBarIcon: icons[0], snackBarText: "Updating Password!" });
    if (password.newPassword === password.confirmNewPassword) {
      if ((password.newPassword.length >= 8
        && password.newPassword.length <= 10)
        && (containsSpecialChars(password.newPassword) === true)) {
        const response = await noAuthPatchAPI(`${PASSWORD_RESET_COMPLETE}${resetPasswordHash}/`, { password: password.newPassword });
        if (response.isError) {
          setSnackBar({ ...snackBar, snackBarIcon: icons[2], snackBarText: "Something Went Wrong!" });
        }
        else {
          setPassword({ ...password, isPasswordChanged: true });
          setSnackBar({ ...snackBar, snackBarIcon: icons[1], snackBarText: "Password Changed Successfully!" });
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        }
      }
      else {
        setTimeout(() => {
          setSnackBar({ ...snackBar, snackBarIcon: icons[2], snackBarText: "Password Length Should Be Around 8-10 Characters Containing At Least One Special Character!" });
        }, 200);
      }
    }
    else {
      setTimeout(() => {
        setSnackBar({ ...snackBar, snackBarIcon: icons[2], snackBarText: "Passwords Do Not Match" });
      }, 200);
    }
  };

  const handleNewPasswordInput = (e) => {
    const { value, name } = e.target;
    setPassword({ ...password, [name]: value });
  };

  useEffect(() => {
    resetTokenValidity();
  }, []);

  return (
    <>
      <Loader displayState={spinnerDisplay} />
      <div className="login">
        <div className="tl">
          <img src={logo} alt="logo" className="logo-auth p-4" />
          {
            authStatus.isLoggedIn
              ? (
                <div className="login-form position-absolute top-50 start-50 translate-middle p-4">
                  <h2 className="mb-3">Password Reset</h2>
                  <form onSubmit={handleFormSubmission}>
                    <div className="mb-3 d-flex align-items-center">
                      <img src={pwd} alt="Password" />
                      <input
                        name="newPassword"
                        value={password.newPassword}
                        onChange={handleNewPasswordInput}
                        type={isPasswordShown
                          ? "text"
                          : "password"}
                        className="input-data form-control mx-4"
                        placeholder="New Password"
                        required
                      />
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                      <img src={access} alt="Password" />
                      <input
                        name="confirmNewPassword"
                        value={password.confirmNewPassword}
                        onChange={handleNewPasswordInput}
                        type={isPasswordShown
                          ? "text"
                          : "password"}
                        className="input-data form-control mx-4"
                        placeholder="Confirm Password"
                        required
                      />
                    </div>
                    <div className="mb-3 d-flex align-items-center justify-content-start mx-4">
                      <div className="mx-5 d-flex gap-2">
                        <input
                          id="checkbox"
                          type="checkbox"
                          checked={isPasswordShown}
                          onChange={togglePassword}
                        />
                        <span className="text-white">Show password</span>
                      </div>
                    </div>
                    <Collapse in={openCollapse} dimension="width">
                      <div id="example-collapse-text">
                        <div className="mb-3 d-flex align-items-center gap-4  login-spinner">
                          <div className={snackBar.snackBarIcon} aria-hidden="true" />
                          <h5 className="text-white mx-3 text-center">{snackBar.snackBarText}</h5>
                        </div>
                      </div>
                    </Collapse>
                    <div className="mb-3 mx-5 d-flex align-items-center justify-content-center gap-2 flex-wrap">
                      <button type="submit" className="btn btn-primary mx-4" disabled={password.isPasswordChanged}>Reset Password</button>
                      <Link to="/login">Return to Login</Link>
                    </div>
                  </form>
                </div>
              )
              : (
                <div
                  className="login-form position-absolute top-50 start-50 translate-middle p-4"
                  style={{
                    display: authStatus.display
                      ? ""
                      : "none",
                  }}
                >
                  <h2>Invalid or expired token</h2>
                  <p className="text-white text-center pt-3">
                    Redirecting you to
                    <span className="mx-1"><Link to="/login">Login</Link></span>
                    in
                    <AutoIncrementCounter />
                    seconds
                  </p>
                </div>
              )
          }
        </div>
      </div>
    </>
  );
};

export default ResetPasswordConfirm;
