import { Modal } from "react-bootstrap";
import SimpleSpinner from "../SimpleSpinner";

const ConfirmModal = ({ show, onClose, title, okTxt, cancelTxt, statement, onOkClick, onCancelClick, isLoading = false  }) => 
    ( <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center font-weight-bold">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center font-weight-lighter">
            {statement}
          </p>
          <div className="approved-btns">
           {isLoading 
           ? <SimpleSpinner /> 
           : <button
              className="approved-button approved"
              onClick={onOkClick}
              type="submit"
            >
              {okTxt}
            </button>}
            <button
              className="approved-button"
              onClick={onCancelClick}
              type="submit"
            >
              {cancelTxt}
            </button>
          </div>
        </Modal.Body>
      </Modal> 
      );
 
export default ConfirmModal;