import React, { useState, useEffect } from "react";
import { getUserInfo } from "../../../utils/localStorage";
import { updateAuthToken } from "../../../utils/api";
import { pfp } from "../../../assets/images";
import "./Profile.css";

const Profile = () => {
  const [userInfo] = useState(JSON.parse(getUserInfo()));
  const {
    first_name,
    last_name, status,
    email,
    phone,
    address,
    cnic,
    profile_image,
    date_joined,
    city,
    country,
    state,
    account_number,
    time_slot,
  } = userInfo;

  useEffect(() => {
    updateAuthToken();
  }, []);

  return (
    <div>
      <div className="mt-2 container d-flex flex-wrap-reverse justify-content-around align-items-center">
        <div className="user-data py-2">
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">Name:</span>
            <input className="input-tag" readOnly value={`${first_name} ${last_name}`} />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">Email:</span>
            <input className="input-tag" readOnly value={email} />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">Designation:</span>
            <input className="input-tag" readOnly value={status} />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">CNIC:</span>
            <input className="input-tag" readOnly value={cnic} />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">Account No:</span>
            <input className="input-tag" readOnly value={account_number} />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">Phone Number:</span>
            <input className="input-tag" readOnly value={phone} />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">Date Of Joining:</span>
            <input className="input-tag" readOnly value={date_joined.substring(0, 10)} />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">Time Slot:</span>
            <input
              className="input-tag"
              readOnly
              value={!time_slot
                ? "Not Available"
                : time_slot}
            />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">Address:</span>
            <input className="input-tag" readOnly value={address} />
          </div>
          <div className="d-flex my-3 justify-content-between">
            <span className="input-label px-2 my-1">City - State - Country:</span>
            <input className="input-tag" readOnly value={`${city.charAt(0).toUpperCase() + city.slice(1)} - ${state.charAt(0).toUpperCase() + state.slice(1)} - ${country.charAt(0).toUpperCase() + country.slice(1)}`} />
          </div>
        </div>
        <div className="user-profile-picture d-flex align-items-center ">
          <img
            src={
              profile_image === null
                ? pfp
                : profile_image
            }
            className="profile-image"
            alt="ProfilePic"
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
